/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { CommunicationApiConfiguration } from '../communication-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CmmNotificationFullDataDtoDto } from '../models/cmm-notification-full-data-dto-dto';
import { NotificationsListResponseDto } from '../models/notifications-list-response-dto';
import { NotificationsResponseDto } from '../models/notifications-response-dto';


/**
 * the Notification API
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseService {
  constructor(
    config: CommunicationApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation notificationListExtended
   */
  static readonly NotificationListExtendedPath = '/notification';

  /**
   * Get list of the notifications in full mode
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationListExtended()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationListExtended$Response(params?: {
    filter?: Array<string>;
    sort?: Array<string>;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<NotificationsListResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.NotificationListExtendedPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
      rb.query('sort', params.sort, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationsListResponseDto>;
      })
    );
  }

  /**
   * Get list of the notifications in full mode
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationListExtended$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationListExtended(params?: {
    filter?: Array<string>;
    sort?: Array<string>;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<NotificationsListResponseDto> {

    return this.notificationListExtended$Response(params,context).pipe(
      map((r: StrictHttpResponse<NotificationsListResponseDto>) => r.body as NotificationsListResponseDto)
    );
  }

  /**
   * Path part for operation objectNotification
   */
  static readonly ObjectNotificationPath = '/notification/{objectType}/{objectUid}';

  /**
   * Get list of notification for sepcified object. An access to the notification is granted only if the caller will present proper token in the X-Authorization header.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `objectNotification()` instead.
   *
   * This method doesn't expect any request body.
   */
  objectNotification$Response(params: {

    /**
     * Object type to read notification for
     */
    objectType: string;

    /**
     * Object unique identifier to read notifcation for
     */
    objectUid: string;
    'X-Authorization'?: string;
    sort?: Array<string>;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<NotificationsListResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.ObjectNotificationPath, 'get');
    if (params) {
      rb.path('objectType', params.objectType, {});
      rb.path('objectUid', params.objectUid, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
      rb.query('sort', params.sort, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationsListResponseDto>;
      })
    );
  }

  /**
   * Get list of notification for sepcified object. An access to the notification is granted only if the caller will present proper token in the X-Authorization header.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `objectNotification$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  objectNotification(params: {

    /**
     * Object type to read notification for
     */
    objectType: string;

    /**
     * Object unique identifier to read notifcation for
     */
    objectUid: string;
    'X-Authorization'?: string;
    sort?: Array<string>;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<NotificationsListResponseDto> {

    return this.objectNotification$Response(params,context).pipe(
      map((r: StrictHttpResponse<NotificationsListResponseDto>) => r.body as NotificationsListResponseDto)
    );
  }

  /**
   * Path part for operation notificationFullDataForObject
   */
  static readonly NotificationFullDataForObjectPath = '/notification/{objectType}/{objectUid}/{id}';

  /**
   * Get list of the notifications in full mode
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationFullDataForObject()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationFullDataForObject$Response(params: {

    /**
     * Object type to read notification for
     */
    objectType: string;

    /**
     * Object unique identifier to read notifcation for
     */
    objectUid: string;
    id: number;
    'X-Authorization'?: string;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CmmNotificationFullDataDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.NotificationFullDataForObjectPath, 'get');
    if (params) {
      rb.path('objectType', params.objectType, {});
      rb.path('objectUid', params.objectUid, {});
      rb.path('id', params.id, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CmmNotificationFullDataDtoDto>;
      })
    );
  }

  /**
   * Get list of the notifications in full mode
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationFullDataForObject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationFullDataForObject(params: {

    /**
     * Object type to read notification for
     */
    objectType: string;

    /**
     * Object unique identifier to read notifcation for
     */
    objectUid: string;
    id: number;
    'X-Authorization'?: string;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<CmmNotificationFullDataDtoDto> {

    return this.notificationFullDataForObject$Response(params,context).pipe(
      map((r: StrictHttpResponse<CmmNotificationFullDataDtoDto>) => r.body as CmmNotificationFullDataDtoDto)
    );
  }

  /**
   * Path part for operation notificationList
   */
  static readonly NotificationListPath = '/notification/{channel}';

  /**
   * get list of notification for channels [APP, API]
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationList()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationList$Response(params: {
    channel: 'MAIL' | 'SMS' | 'API' | 'APP';
    filter?: Array<string>;
    sort?: Array<string>;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<NotificationsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.NotificationListPath, 'get');
    if (params) {
      rb.path('channel', params.channel, {});
      rb.query('filter', params.filter, {});
      rb.query('sort', params.sort, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationsResponseDto>;
      })
    );
  }

  /**
   * get list of notification for channels [APP, API]
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationList(params: {
    channel: 'MAIL' | 'SMS' | 'API' | 'APP';
    filter?: Array<string>;
    sort?: Array<string>;
  },
  context?: HttpContext

): Observable<NotificationsResponseDto> {

    return this.notificationList$Response(params,context).pipe(
      map((r: StrictHttpResponse<NotificationsResponseDto>) => r.body as NotificationsResponseDto)
    );
  }

  /**
   * Path part for operation notificationFullData
   */
  static readonly NotificationFullDataPath = '/notification-details/{id}';

  /**
   * Get list of the notifications in full mode
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationFullData()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationFullData$Response(params: {
    id: number;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CmmNotificationFullDataDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.NotificationFullDataPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CmmNotificationFullDataDtoDto>;
      })
    );
  }

  /**
   * Get list of the notifications in full mode
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationFullData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationFullData(params: {
    id: number;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<CmmNotificationFullDataDtoDto> {

    return this.notificationFullData$Response(params,context).pipe(
      map((r: StrictHttpResponse<CmmNotificationFullDataDtoDto>) => r.body as CmmNotificationFullDataDtoDto)
    );
  }

}
