/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { PaymentApiConfiguration } from '../payment-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PamInstallmentProductSummaryResponseDto } from '../models/pam-installment-product-summary-response-dto';


/**
 * the PamQueryInstallmentProductSummary API
 */
@Injectable({
  providedIn: 'root',
})
export class PamQueryInstallmentProductSummaryService extends BaseService {
  constructor(
    config: PaymentApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation installmentProductSummaryFetch
   */
  static readonly InstallmentProductSummaryFetchPath = '/installment/{id}/product-summary';

  /**
   * Provide product summary for the installment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `installmentProductSummaryFetch()` instead.
   *
   * This method doesn't expect any request body.
   */
  installmentProductSummaryFetch$Response(params: {

    /**
     * The installment_uuid can be used to read installment
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PamInstallmentProductSummaryResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PamQueryInstallmentProductSummaryService.InstallmentProductSummaryFetchPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PamInstallmentProductSummaryResponseDto>;
      })
    );
  }

  /**
   * Provide product summary for the installment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `installmentProductSummaryFetch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  installmentProductSummaryFetch(params: {

    /**
     * The installment_uuid can be used to read installment
     */
    id: string;
  },
  context?: HttpContext

): Observable<PamInstallmentProductSummaryResponseDto> {

    return this.installmentProductSummaryFetch$Response(params,context).pipe(
      map((r: StrictHttpResponse<PamInstallmentProductSummaryResponseDto>) => r.body as PamInstallmentProductSummaryResponseDto)
    );
  }

}
