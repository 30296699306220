/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { SalesApiConfiguration } from '../sales-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SamProductRolesResponseDtoDto } from '../models/sam-product-roles-response-dto-dto';
import { SamRoleRequestDtoDto } from '../models/sam-role-request-dto-dto';
import { SamRolesResponseDto } from '../models/sam-roles-response-dto';


/**
 * the Person registration API
 */
@Injectable({
  providedIn: 'root',
})
export class PersonRegistrationService extends BaseService {
  constructor(
    config: SalesApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation updateRoles
   */
  static readonly UpdateRolesPath = '/person/registration';

  /**
   * Update roles on offer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRoles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRoles$Response(params: {

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    productCode: string;
    'X-Authorization'?: string;
    body: SamRoleRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SamRolesResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PersonRegistrationService.UpdateRolesPath, 'put');
    if (params) {
      rb.query('caller', params.caller, {});
      rb.query('productCode', params.productCode, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SamRolesResponseDto>;
      })
    );
  }

  /**
   * Update roles on offer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRoles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRoles(params: {

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    productCode: string;
    'X-Authorization'?: string;
    body: SamRoleRequestDtoDto
  },
  context?: HttpContext

): Observable<SamRolesResponseDto> {

    return this.updateRoles$Response(params,context).pipe(
      map((r: StrictHttpResponse<SamRolesResponseDto>) => r.body as SamRolesResponseDto)
    );
  }

  /**
   * Path part for operation createRoles
   */
  static readonly CreateRolesPath = '/person/registration';

  /**
   * Create all roles on offer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRoles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRoles$Response(params: {

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    productCode: string;
    'X-Authorization'?: string;
    body: SamRoleRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SamRolesResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PersonRegistrationService.CreateRolesPath, 'post');
    if (params) {
      rb.query('caller', params.caller, {});
      rb.query('productCode', params.productCode, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SamRolesResponseDto>;
      })
    );
  }

  /**
   * Create all roles on offer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRoles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRoles(params: {

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    productCode: string;
    'X-Authorization'?: string;
    body: SamRoleRequestDtoDto
  },
  context?: HttpContext

): Observable<SamRolesResponseDto> {

    return this.createRoles$Response(params,context).pipe(
      map((r: StrictHttpResponse<SamRolesResponseDto>) => r.body as SamRolesResponseDto)
    );
  }

  /**
   * Path part for operation personRoleFetch
   */
  static readonly PersonRoleFetchPath = '/person/{productCode}';

  /**
   * Return the definition of product roles for selected product
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personRoleFetch()` instead.
   *
   * This method doesn't expect any request body.
   */
  personRoleFetch$Response(params: {
    productCode: string;

    /**
     * partnerCode
     */
    partnerCode: string;

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    'X-Authorization'?: string;

    /**
     * Offer Id (if known)
     */
    offerId?: string;

    /**
     * Code of the employee of the partner
     */
    employeeCode?: string;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SamProductRolesResponseDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, PersonRegistrationService.PersonRoleFetchPath, 'get');
    if (params) {
      rb.path('productCode', params.productCode, {});
      rb.query('partnerCode', params.partnerCode, {});
      rb.query('caller', params.caller, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
      rb.query('offerId', params.offerId, {});
      rb.query('employeeCode', params.employeeCode, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SamProductRolesResponseDtoDto>;
      })
    );
  }

  /**
   * Return the definition of product roles for selected product
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `personRoleFetch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personRoleFetch(params: {
    productCode: string;

    /**
     * partnerCode
     */
    partnerCode: string;

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    'X-Authorization'?: string;

    /**
     * Offer Id (if known)
     */
    offerId?: string;

    /**
     * Code of the employee of the partner
     */
    employeeCode?: string;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<SamProductRolesResponseDtoDto> {

    return this.personRoleFetch$Response(params,context).pipe(
      map((r: StrictHttpResponse<SamProductRolesResponseDtoDto>) => r.body as SamProductRolesResponseDtoDto)
    );
  }

}
