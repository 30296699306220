/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { DocumentsApiConfiguration } from '../documents-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DocumentDeletedListResponseDto } from '../models/document-deleted-list-response-dto';
import { DocumentExportRequestDto } from '../models/document-export-request-dto';
import { DocumentImportRequestDto } from '../models/document-import-request-dto';
import { DocumentListResponseDto } from '../models/document-list-response-dto';
import { DocumentUrIsDtoDto } from '../models/document-ur-is-dto-dto';
import { DomDocumentDownloadUrlResponseDto } from '../models/dom-document-download-url-response-dto';
import { DomDocumentMetadataResponseDto } from '../models/dom-document-metadata-response-dto';
import { DomDocumentUploadResponseDto } from '../models/dom-document-upload-response-dto';
import { DomUploadedDocumentRequestDto } from '../models/dom-uploaded-document-request-dto';


/**
 * the Documents API
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentsService extends BaseService {
  constructor(
    config: DocumentsApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation documentDeleteImported
   */
  static readonly DocumentDeleteImportedPath = '/document/bulk-delete';

  /**
   * import document metadata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentDeleteImported()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentDeleteImported$Response(params: {
    body: DocumentUrIsDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DocumentDeletedListResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.DocumentDeleteImportedPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentDeletedListResponseDto>;
      })
    );
  }

  /**
   * import document metadata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentDeleteImported$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentDeleteImported(params: {
    body: DocumentUrIsDtoDto
  },
  context?: HttpContext

): Observable<DocumentDeletedListResponseDto> {

    return this.documentDeleteImported$Response(params,context).pipe(
      map((r: StrictHttpResponse<DocumentDeletedListResponseDto>) => r.body as DocumentDeletedListResponseDto)
    );
  }

  /**
   * Path part for operation upload
   */
  static readonly UploadPath = '/document/upload';

  /**
   * Upload new document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upload()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  upload$Response(params: {
    body: DomUploadedDocumentRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DomDocumentUploadResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.UploadPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DomDocumentUploadResponseDto>;
      })
    );
  }

  /**
   * Upload new document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upload$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  upload(params: {
    body: DomUploadedDocumentRequestDto
  },
  context?: HttpContext

): Observable<DomDocumentUploadResponseDto> {

    return this.upload$Response(params,context).pipe(
      map((r: StrictHttpResponse<DomDocumentUploadResponseDto>) => r.body as DomDocumentUploadResponseDto)
    );
  }

  /**
   * Path part for operation documentImport
   */
  static readonly DocumentImportPath = '/document/import';

  /**
   * import document metadata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentImport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentImport$Response(params: {
    body: DocumentImportRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DocumentListResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.DocumentImportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentListResponseDto>;
      })
    );
  }

  /**
   * import document metadata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentImport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentImport(params: {
    body: DocumentImportRequestDto
  },
  context?: HttpContext

): Observable<DocumentListResponseDto> {

    return this.documentImport$Response(params,context).pipe(
      map((r: StrictHttpResponse<DocumentListResponseDto>) => r.body as DocumentListResponseDto)
    );
  }

  /**
   * Path part for operation getSignedUrl
   */
  static readonly GetSignedUrlPath = '/document/{uri}/signed-url';

  /**
   * Get document signedURL to download content
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSignedUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSignedUrl$Response(params: {
    uri: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DomDocumentDownloadUrlResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.GetSignedUrlPath, 'get');
    if (params) {
      rb.path('uri', params.uri, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DomDocumentDownloadUrlResponseDto>;
      })
    );
  }

  /**
   * Get document signedURL to download content
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSignedUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSignedUrl(params: {
    uri: string;
  },
  context?: HttpContext

): Observable<DomDocumentDownloadUrlResponseDto> {

    return this.getSignedUrl$Response(params,context).pipe(
      map((r: StrictHttpResponse<DomDocumentDownloadUrlResponseDto>) => r.body as DomDocumentDownloadUrlResponseDto)
    );
  }

  /**
   * Path part for operation technicalSignedUrl
   */
  static readonly TechnicalSignedUrlPath = '/document/{uri}/signed-url/technical';

  /**
   * Get document signedURL to download content
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `technicalSignedUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  technicalSignedUrl$Response(params: {
    uri: string;
    'X-Authorization'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DomDocumentDownloadUrlResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.TechnicalSignedUrlPath, 'get');
    if (params) {
      rb.path('uri', params.uri, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DomDocumentDownloadUrlResponseDto>;
      })
    );
  }

  /**
   * Get document signedURL to download content
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `technicalSignedUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  technicalSignedUrl(params: {
    uri: string;
    'X-Authorization'?: string;
  },
  context?: HttpContext

): Observable<DomDocumentDownloadUrlResponseDto> {

    return this.technicalSignedUrl$Response(params,context).pipe(
      map((r: StrictHttpResponse<DomDocumentDownloadUrlResponseDto>) => r.body as DomDocumentDownloadUrlResponseDto)
    );
  }

  /**
   * Path part for operation getMetadata
   */
  static readonly GetMetadataPath = '/document/{uri}/metadata';

  /**
   * Fetch document data by document uri.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMetadata()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetadata$Response(params: {
    uri: string;
    internalClient?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DomDocumentMetadataResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.GetMetadataPath, 'get');
    if (params) {
      rb.path('uri', params.uri, {});
      rb.query('internalClient', params.internalClient, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DomDocumentMetadataResponseDto>;
      })
    );
  }

  /**
   * Fetch document data by document uri.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMetadata$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetadata(params: {
    uri: string;
    internalClient?: boolean;
  },
  context?: HttpContext

): Observable<DomDocumentMetadataResponseDto> {

    return this.getMetadata$Response(params,context).pipe(
      map((r: StrictHttpResponse<DomDocumentMetadataResponseDto>) => r.body as DomDocumentMetadataResponseDto)
    );
  }

  /**
   * Path part for operation technicalMetadata
   */
  static readonly TechnicalMetadataPath = '/document/{uri}/metadata/technical';

  /**
   * Fetch document data by document uri.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `technicalMetadata()` instead.
   *
   * This method doesn't expect any request body.
   */
  technicalMetadata$Response(params: {
    uri: string;
    internalClient?: boolean;
    'X-Authorization'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DomDocumentMetadataResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.TechnicalMetadataPath, 'get');
    if (params) {
      rb.path('uri', params.uri, {});
      rb.query('internalClient', params.internalClient, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DomDocumentMetadataResponseDto>;
      })
    );
  }

  /**
   * Fetch document data by document uri.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `technicalMetadata$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  technicalMetadata(params: {
    uri: string;
    internalClient?: boolean;
    'X-Authorization'?: string;
  },
  context?: HttpContext

): Observable<DomDocumentMetadataResponseDto> {

    return this.technicalMetadata$Response(params,context).pipe(
      map((r: StrictHttpResponse<DomDocumentMetadataResponseDto>) => r.body as DomDocumentMetadataResponseDto)
    );
  }

  /**
   * Path part for operation getContent
   */
  static readonly GetContentPath = '/document/{uri}/content';

  /**
   * Fetch document content by document uri.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContent$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContent$Json$Response(params: {
    uri: string;
    'X-PASSWORD'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.GetContentPath, 'get');
    if (params) {
      rb.path('uri', params.uri, {});
      rb.header('X-PASSWORD', params['X-PASSWORD'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Fetch document content by document uri.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContent$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContent$Json(params: {
    uri: string;
    'X-PASSWORD'?: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.getContent$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Fetch document content by document uri.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContent$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContent$Any$Response(params: {
    uri: string;
    'X-PASSWORD'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.GetContentPath, 'get');
    if (params) {
      rb.path('uri', params.uri, {});
      rb.header('X-PASSWORD', params['X-PASSWORD'], {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Fetch document content by document uri.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContent$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContent$Any(params: {
    uri: string;
    'X-PASSWORD'?: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.getContent$Any$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation documentExport
   */
  static readonly DocumentExportPath = '/document/export';

  /**
   * export document metadata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentExport()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentExport$Response(params: {
    DocumentExportRequestDto: DocumentExportRequestDto;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DocumentListResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.DocumentExportPath, 'get');
    if (params) {
      rb.query('DocumentExportRequestDto', params.DocumentExportRequestDto, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentListResponseDto>;
      })
    );
  }

  /**
   * export document metadata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentExport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentExport(params: {
    DocumentExportRequestDto: DocumentExportRequestDto;
  },
  context?: HttpContext

): Observable<DocumentListResponseDto> {

    return this.documentExport$Response(params,context).pipe(
      map((r: StrictHttpResponse<DocumentListResponseDto>) => r.body as DocumentListResponseDto)
    );
  }

}
