/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { PersonApiConfiguration } from '../person-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PemBlockedPersonResponseDtoDto } from '../models/pem-blocked-person-response-dto-dto';


/**
 * the BlockedPersonByParameters API
 */
@Injectable({
  providedIn: 'root',
})
export class BlockedPersonByParametersService extends BaseService {
  constructor(
    config: PersonApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation blockedPersonByParameters
   */
  static readonly BlockedPersonByParametersPath = '/blocked-person';

  /**
   * Get list of blocked people by email, citizenship, taxId and personalId (all might be optional)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blockedPersonByParameters()` instead.
   *
   * This method doesn't expect any request body.
   */
  blockedPersonByParameters$Response(params?: {
    mail?: string;
    taxId?: string;
    personalId?: string;
    citizenship?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PemBlockedPersonResponseDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, BlockedPersonByParametersService.BlockedPersonByParametersPath, 'get');
    if (params) {
      rb.query('mail', params.mail, {});
      rb.query('taxId', params.taxId, {});
      rb.query('personalId', params.personalId, {});
      rb.query('citizenship', params.citizenship, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PemBlockedPersonResponseDtoDto>;
      })
    );
  }

  /**
   * Get list of blocked people by email, citizenship, taxId and personalId (all might be optional)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `blockedPersonByParameters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  blockedPersonByParameters(params?: {
    mail?: string;
    taxId?: string;
    personalId?: string;
    citizenship?: string;
  },
  context?: HttpContext

): Observable<PemBlockedPersonResponseDtoDto> {

    return this.blockedPersonByParameters$Response(params,context).pipe(
      map((r: StrictHttpResponse<PemBlockedPersonResponseDtoDto>) => r.body as PemBlockedPersonResponseDtoDto)
    );
  }

}
