/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { OfferApiConfiguration } from '../offer-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PomConsentIdResponseDto } from '../models/pom-consent-id-response-dto';
import { PomConsentRequestDtoDto } from '../models/pom-consent-request-dto-dto';
import { PomConsentResponseDto } from '../models/pom-consent-response-dto';
import { PomConsentsResponseDto } from '../models/pom-consents-response-dto';
import { ResultDetailsDto } from '../models/result-details-dto';

@Injectable({
  providedIn: 'root',
})
export class ConsentService extends BaseService {
  constructor(
    config: OfferApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation fetch9
   */
  static readonly Fetch9Path = '/consent/{consentId}';

  /**
   * Get consent details.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetch9()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetch9$Response(params: {

    /**
     * Id of the consent that should be get
     */
    consentId: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomConsentResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ConsentService.Fetch9Path, 'get');
    if (params) {
      rb.path('consentId', params.consentId, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomConsentResponseDto>;
      })
    );
  }

  /**
   * Get consent details.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fetch9$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetch9(params: {

    /**
     * Id of the consent that should be get
     */
    consentId: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<PomConsentResponseDto> {

    return this.fetch9$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomConsentResponseDto>) => r.body as PomConsentResponseDto)
    );
  }

  /**
   * Path part for operation update7
   */
  static readonly Update7Path = '/consent/{consentId}';

  /**
   * Update consent.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update7()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update7$Response(params: {

    /**
     * Id of the consent that should be updated
     */
    consentId: number;
    'Accept-Language'?: any;
    body: PomConsentRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ResultDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, ConsentService.Update7Path, 'put');
    if (params) {
      rb.path('consentId', params.consentId, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultDetailsDto>;
      })
    );
  }

  /**
   * Update consent.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update7$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update7(params: {

    /**
     * Id of the consent that should be updated
     */
    consentId: number;
    'Accept-Language'?: any;
    body: PomConsentRequestDtoDto
  },
  context?: HttpContext

): Observable<ResultDetailsDto> {

    return this.update7$Response(params,context).pipe(
      map((r: StrictHttpResponse<ResultDetailsDto>) => r.body as ResultDetailsDto)
    );
  }

  /**
   * Path part for operation list11
   */
  static readonly List11Path = '/consent';

  /**
   * Get list of consent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `list11()` instead.
   *
   * This method doesn't expect any request body.
   */
  list11$Response(params: {

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * Filter that can be applied to the list of results
     */
    filter?: Array<string>;

    /**
     * Sorting that can be applied to the list of results
     */
    sort?: Array<string>;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomConsentsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ConsentService.List11Path, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('filter', params.filter, {});
      rb.query('sort', params.sort, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomConsentsResponseDto>;
      })
    );
  }

  /**
   * Get list of consent
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `list11$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  list11(params: {

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * Filter that can be applied to the list of results
     */
    filter?: Array<string>;

    /**
     * Sorting that can be applied to the list of results
     */
    sort?: Array<string>;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<PomConsentsResponseDto> {

    return this.list11$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomConsentsResponseDto>) => r.body as PomConsentsResponseDto)
    );
  }

  /**
   * Path part for operation create9
   */
  static readonly Create9Path = '/consent';

  /**
   * Create new consent.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create9()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create9$Response(params: {
    'Accept-Language'?: any;
    body: PomConsentRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomConsentIdResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ConsentService.Create9Path, 'post');
    if (params) {
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomConsentIdResponseDto>;
      })
    );
  }

  /**
   * Create new consent.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create9$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create9(params: {
    'Accept-Language'?: any;
    body: PomConsentRequestDtoDto
  },
  context?: HttpContext

): Observable<PomConsentIdResponseDto> {

    return this.create9$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomConsentIdResponseDto>) => r.body as PomConsentIdResponseDto)
    );
  }

}
