/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { OfferApiConfiguration } from '../offer-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PomVariantPresentationResponseDto } from '../models/pom-variant-presentation-response-dto';


/**
 * the Product variant presentation API
 */
@Injectable({
  providedIn: 'root',
})
export class ProductVariantPresentationService extends BaseService {
  constructor(
    config: OfferApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation productVariantsPresentation
   */
  static readonly ProductVariantsPresentationPath = '/product-presentation/{productCode}/variant';

  /**
   * Get list of variants for a product
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productVariantsPresentation()` instead.
   *
   * This method doesn't expect any request body.
   */
  productVariantsPresentation$Response(params: {

    /**
     * Product code
     */
    productCode: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomVariantPresentationResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductVariantPresentationService.ProductVariantsPresentationPath, 'get');
    if (params) {
      rb.path('productCode', params.productCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomVariantPresentationResponseDto>;
      })
    );
  }

  /**
   * Get list of variants for a product
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `productVariantsPresentation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productVariantsPresentation(params: {

    /**
     * Product code
     */
    productCode: string;
  },
  context?: HttpContext

): Observable<PomVariantPresentationResponseDto> {

    return this.productVariantsPresentation$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomVariantPresentationResponseDto>) => r.body as PomVariantPresentationResponseDto)
    );
  }

}
