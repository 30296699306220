/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ClaimApiConfiguration } from '../claim-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClmClaimPersonRequestDto } from '../models/clm-claim-person-request-dto';


/**
 * the ClaCommandCreatePerson API
 */
@Injectable({
  providedIn: 'root',
})
export class ClaCommandCreatePersonService extends BaseService {
  constructor(
    config: ClaimApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation claimClaimIdPersonPut
   */
  static readonly ClaimClaimIdPersonPutPath = '/claim/{claimId}/person';

  /**
   * Create or a claim persons.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `claimClaimIdPersonPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  claimClaimIdPersonPut$Response(params: {
    claimId: string;
    language: string;
    body: ClmClaimPersonRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ClaCommandCreatePersonService.ClaimClaimIdPersonPutPath, 'put');
    if (params) {
      rb.path('claimId', params.claimId, {});
      rb.query('language', params.language, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Create or a claim persons.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `claimClaimIdPersonPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  claimClaimIdPersonPut(params: {
    claimId: string;
    language: string;
    body: ClmClaimPersonRequestDto
  },
  context?: HttpContext

): Observable<void> {

    return this.claimClaimIdPersonPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
