/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PersonApiConfiguration, PersonApiConfigurationParams } from './person-api-configuration';

import { PersonService } from './services/person.service';
import { ConsentService } from './services/consent.service';
import { BlockedPersonService } from './services/blocked-person.service';
import { BlockedPersonByParametersService } from './services/blocked-person-by-parameters.service';
import { PersonWithConsentsService } from './services/person-with-consents.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    PersonService,
    ConsentService,
    BlockedPersonService,
    BlockedPersonByParametersService,
    PersonWithConsentsService,
    PersonApiConfiguration
  ],
})
export class PersonApiModule {
  static forRoot(params: PersonApiConfigurationParams): ModuleWithProviders<PersonApiModule> {
    return {
      ngModule: PersonApiModule,
      providers: [
        {
          provide: PersonApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: PersonApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('PersonApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
