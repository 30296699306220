/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ClaimApiConfiguration } from '../claim-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClmClaimRegisterRequestDto } from '../models/clm-claim-register-request-dto';
import { ClmExternalClaimResponseDto } from '../models/clm-external-claim-response-dto';


/**
 * the ClaimRegister API
 */
@Injectable({
  providedIn: 'root',
})
export class ClaimRegisterService extends BaseService {
  constructor(
    config: ClaimApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation claimRegisterCommand
   */
  static readonly ClaimRegisterCommandPath = '/claim/register';

  /**
   * Register claim.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `claimRegisterCommand()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  claimRegisterCommand$Response(params: {
    tpaCode?: string;
    body: ClmClaimRegisterRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClmExternalClaimResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClaimRegisterService.ClaimRegisterCommandPath, 'post');
    if (params) {
      rb.query('tpaCode', params.tpaCode, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClmExternalClaimResponseDto>;
      })
    );
  }

  /**
   * Register claim.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `claimRegisterCommand$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  claimRegisterCommand(params: {
    tpaCode?: string;
    body: ClmClaimRegisterRequestDto
  },
  context?: HttpContext

): Observable<ClmExternalClaimResponseDto> {

    return this.claimRegisterCommand$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClmExternalClaimResponseDto>) => r.body as ClmExternalClaimResponseDto)
    );
  }

}
