import { Injectable } from '@angular/core';
import { PersonRegistrationService } from '../../../_api/sales/services';
import {InsuredPeopleRoleGroup} from '../models/insured-people/InsuredPeopleRoleGroup';
import { Observable } from 'rxjs';
import { SamPersonDto, SamPersonRoleDto, SamProductRolesResponseDtoDto, SamRoleRequestDtoDto } from '../../../_api/sales/models';
import { InsuredPeopleRole } from '../models/insured-people/InsuredPeopleRole';
import { InsuredPeople } from '../models/insured-people/InsuredPeople';
import { InsuredPersonParameterValue } from '../models/insured-people/InsuredPersonParameterValue';
import {set} from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class PersonRegistrationAdapterService {

  constructor(private personRegistrationService: PersonRegistrationService) { }

  createRoles(productCode: string, offerId: string, data: InsuredPeople) {
    return this.personRegistrationService.createRoles({caller: 'FEC', productCode, body: this.mapInsuredPeople(data, offerId)});
  }

  getGroupedRoles(productCode: string, partnerCode: string, lang: string): Observable<InsuredPeopleRoleGroup[]> {
    return new Observable(subscriber => {
      this.personRegistrationService
        .personRoleFetch({productCode, partnerCode, caller: 'FEC', 'Accept-Language': lang})
        .subscribe(response => subscriber.next(this.mapResponse(response)));
    });
  }

  private mapResponse(response: SamProductRolesResponseDtoDto): InsuredPeopleRoleGroup[] {
    return response.productRoles.reduce(this.groupByRoleType, [])
      .map(group => ({
        roleType: group.roleType,
        roleName: group.roleName,
        max: Math.max(...group.roles.map(role => role.dataProductRole.max)),
        share: group.roles.some(role => role.dataProductRole.share),
        order: Number(group.roles[0].parameters.find(param => param.code === 'displayOrder')?.value || Number.MAX_SAFE_INTEGER),
        roles: group.roles.map(this.mapRole),
        obligatory: group.roles.some(role => role.dataProductRole.obligatory)
      } as InsuredPeopleRoleGroup))
      .sort((o1, o2) => o1.order - o2.order);
  }

  private groupByRoleType(accumulator, current) {
    const roleType = current.dataProductRole.type.code as string;
    const found = accumulator.find(group => group.roleType === roleType);

    if(found) {
      found.roles.push(current);
    } else {
      accumulator.push({
        roleType: roleType,
        roleName: current.dataProductRole.type.value,
        roles: [current]
      });
    }
    return accumulator;
  }

  private mapRole(current: any): InsuredPeopleRole {
    return {
      personType: current.dataProductRole.personType,
      parameters: current.parameters.filter(param => ['basicRoleData', 'additionalRoleData'].includes(param.group))
    };
  }

  private mapInsuredPeople(data: InsuredPeople, offerId: string): SamRoleRequestDtoDto {
    return {
      offerId,
      roles: Object.keys(data)
        .flatMap(roleType => data[roleType].map(role => ({...role, roleType})))
        .map(role => ({
          roleType: role.roleType,
          share: role.share,
          person: this.mapToPerson(role.values, role.personType)
        } as SamPersonRoleDto))
      };
  }

  private mapToPerson(personParamtersValues: InsuredPersonParameterValue[], personType: string): SamPersonDto {
    return {
      personType: personType as SamPersonDto['personType'],
      ...this.toPersonProperties(personParamtersValues)
    };
  }

  private toPersonProperties(personParamtersValues: InsuredPersonParameterValue[]): SamPersonDto {
    const result = {} as SamPersonDto;

    result.additionalInformation = { "params": [] };
    personParamtersValues.forEach(paramValue => {
      if (paramValue.group === 'basicRoleData') {
        set(result, paramValue.code, paramValue.value);
      } else {
        let obj = {}
        set(obj, "code", paramValue.code);
        set(obj, "group", paramValue.group);
        set(obj, "value", paramValue.value);
        set(obj, "showInFEC", true);
        if (result.additionalInformation) {
          (result.additionalInformation['params']).push(obj);
        }
      }
    });

    return result;
  }
}
