import { HttpInterceptorFn } from '@angular/common/http';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  let tokenName: 'salesToken' | 'claimToken';

  if (req.url.includes('api-sales/fec')) {
    tokenName = 'salesToken';
  } else if (req.url.includes('api-claim/fec')) {
    tokenName = 'claimToken';
  } 
  
  const jwtToken = getJwtToken(tokenName);
  if (jwtToken) {
    var cloned = req.clone({
      setHeaders: {
        'X-Authorization': `Bearer ${jwtToken}`,
      },
    });

    return next(cloned);
  }
  return next(req);
};

function getJwtToken(tokenName: string): string | null {
  let token: any = sessionStorage.getItem(tokenName);
  if (!token) return null;
  return token;
}