/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { OfferApiConfiguration } from '../offer-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PomPartnerRequestDto } from '../models/pom-partner-request-dto';
import { PomPartnerResponseDto } from '../models/pom-partner-response-dto';
import { PomPartnerStylingResponseDto } from '../models/pom-partner-styling-response-dto';
import { PomPartnersResponseDto } from '../models/pom-partners-response-dto';

@Injectable({
  providedIn: 'root',
})
export class PartnersService extends BaseService {
  constructor(
    config: OfferApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation list8
   */
  static readonly List8Path = '/offer/partner';

  /**
   * Get list of the partners
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `list8()` instead.
   *
   * This method doesn't expect any request body.
   */
  list8$Response(params: {

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * Filter that can be applied to the list of results
     */
    filter?: Array<string>;

    /**
     * Sorting that can be applied to the list of results
     */
    sort?: Array<string>;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomPartnersResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PartnersService.List8Path, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('filter', params.filter, {});
      rb.query('sort', params.sort, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomPartnersResponseDto>;
      })
    );
  }

  /**
   * Get list of the partners
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `list8$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  list8(params: {

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * Filter that can be applied to the list of results
     */
    filter?: Array<string>;

    /**
     * Sorting that can be applied to the list of results
     */
    sort?: Array<string>;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<PomPartnersResponseDto> {

    return this.list8$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomPartnersResponseDto>) => r.body as PomPartnersResponseDto)
    );
  }

  /**
   * Path part for operation update5
   */
  static readonly Update5Path = '/offer/partner';

  /**
   * Update partner.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update5()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update5$Response(params: {
    'Accept-Language'?: any;
    body: PomPartnerRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomPartnerResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PartnersService.Update5Path, 'put');
    if (params) {
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomPartnerResponseDto>;
      })
    );
  }

  /**
   * Update partner.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update5$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update5(params: {
    'Accept-Language'?: any;
    body: PomPartnerRequestDto
  },
  context?: HttpContext

): Observable<PomPartnerResponseDto> {

    return this.update5$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomPartnerResponseDto>) => r.body as PomPartnerResponseDto)
    );
  }

  /**
   * Path part for operation create6
   */
  static readonly Create6Path = '/offer/partner';

  /**
   * Create new partner.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create6()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create6$Response(params: {
    'Accept-Language'?: any;
    body: PomPartnerRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomPartnerResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PartnersService.Create6Path, 'post');
    if (params) {
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomPartnerResponseDto>;
      })
    );
  }

  /**
   * Create new partner.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create6$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create6(params: {
    'Accept-Language'?: any;
    body: PomPartnerRequestDto
  },
  context?: HttpContext

): Observable<PomPartnerResponseDto> {

    return this.create6$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomPartnerResponseDto>) => r.body as PomPartnerResponseDto)
    );
  }

  /**
   * Path part for operation offerPartnerPartnerCodeStylingGet
   */
  static readonly OfferPartnerPartnerCodeStylingGetPath = '/offer/partner/{partnerCode}/styling';

  /**
   * Fetch partner stlying for FEC
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerPartnerPartnerCodeStylingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerPartnerPartnerCodeStylingGet$Response(params: {
    partnerCode: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomPartnerStylingResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PartnersService.OfferPartnerPartnerCodeStylingGetPath, 'get');
    if (params) {
      rb.path('partnerCode', params.partnerCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomPartnerStylingResponseDto>;
      })
    );
  }

  /**
   * Fetch partner stlying for FEC
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerPartnerPartnerCodeStylingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerPartnerPartnerCodeStylingGet(params: {
    partnerCode: string;
  },
  context?: HttpContext

): Observable<PomPartnerStylingResponseDto> {

    return this.offerPartnerPartnerCodeStylingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomPartnerStylingResponseDto>) => r.body as PomPartnerStylingResponseDto)
    );
  }

  /**
   * Path part for operation fetch6
   */
  static readonly Fetch6Path = '/offer/partner/{id}';

  /**
   * Fetch partner data by partner id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetch6()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetch6$Response(params: {

    /**
     * Id of the partner that should be get
     */
    id: string;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomPartnerResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PartnersService.Fetch6Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomPartnerResponseDto>;
      })
    );
  }

  /**
   * Fetch partner data by partner id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fetch6$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetch6(params: {

    /**
     * Id of the partner that should be get
     */
    id: string;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<PomPartnerResponseDto> {

    return this.fetch6$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomPartnerResponseDto>) => r.body as PomPartnerResponseDto)
    );
  }

}
