/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OfferApiConfiguration, OfferApiConfigurationParams } from './offer-api-configuration';

import { ProductGtcService } from './services/product-gtc.service';
import { ProductTemporaryService } from './services/product-temporary.service';
import { ProductRoleService } from './services/product-role.service';
import { ProductCategoryService } from './services/product-category.service';
import { OperationDefinitionServicesService } from './services/operation-definition-services.service';
import { ProductVariantService } from './services/product-variant.service';
import { RiskObjectService } from './services/risk-object.service';
import { ProductService } from './services/product.service';
import { PartnersService } from './services/partners.service';
import { InsuranceCompanyServiceService } from './services/insurance-company-service.service';
import { FormServicesService } from './services/form-services.service';
import { ConsentService } from './services/consent.service';
import { BenefitService } from './services/benefit.service';
import { ValidateOfferService } from './services/validate-offer.service';
import { QuotationsService } from './services/quotations.service';
import { OffersService } from './services/offers.service';
import { ProductImportService } from './services/product-import.service';
import { ProductCompanyCodesService } from './services/product-company-codes.service';
import { ProductFullService } from './services/product-full.service';
import { RoleWithParametersService } from './services/role-with-parameters.service';
import { RiskObjectParametersService } from './services/risk-object-parameters.service';
import { ProductPaymentDetailsService } from './services/product-payment-details.service';
import { ProductExportService } from './services/product-export.service';
import { ProductInfoPaymentService } from './services/product-info-payment.service';
import { ProductTaxCodeService } from './services/product-tax-code.service';
import { ProductPresentationService } from './services/product-presentation.service';
import { ProductVariantPresentationService } from './services/product-variant-presentation.service';
import { FormsPresentationService } from './services/forms-presentation.service';
import { ProductDefinitionService } from './services/product-definition.service';
import { ConsentsPresentationService } from './services/consents-presentation.service';
import { StylingService } from './services/styling.service';
import { CategoriesForClaimsService } from './services/categories-for-claims.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ProductGtcService,
    ProductTemporaryService,
    ProductRoleService,
    ProductCategoryService,
    OperationDefinitionServicesService,
    ProductVariantService,
    RiskObjectService,
    ProductService,
    PartnersService,
    InsuranceCompanyServiceService,
    FormServicesService,
    ConsentService,
    BenefitService,
    ValidateOfferService,
    QuotationsService,
    OffersService,
    ProductImportService,
    ProductCompanyCodesService,
    ProductFullService,
    RoleWithParametersService,
    RiskObjectParametersService,
    ProductPaymentDetailsService,
    ProductExportService,
    ProductInfoPaymentService,
    ProductTaxCodeService,
    ProductPresentationService,
    ProductVariantPresentationService,
    FormsPresentationService,
    ProductDefinitionService,
    ConsentsPresentationService,
    StylingService,
    CategoriesForClaimsService,
    OfferApiConfiguration
  ],
})
export class OfferApiModule {
  static forRoot(params: OfferApiConfigurationParams): ModuleWithProviders<OfferApiModule> {
    return {
      ngModule: OfferApiModule,
      providers: [
        {
          provide: OfferApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: OfferApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('OfferApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
