/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { OfferApiConfiguration } from '../offer-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PomActivateOperationDefinitionRequestDtoDto } from '../models/pom-activate-operation-definition-request-dto-dto';
import { PomDeactivateOperationDefinitionRequestDtoDto } from '../models/pom-deactivate-operation-definition-request-dto-dto';
import { PomOperationDefinitionDto } from '../models/pom-operation-definition-dto';
import { PomOperationDefinitionReferenceRequestDtoDto } from '../models/pom-operation-definition-reference-request-dto-dto';
import { PomOperationDefinitionsListDtoDto } from '../models/pom-operation-definitions-list-dto-dto';
import { ResultDetailsDto } from '../models/result-details-dto';


/**
 * the Operation definition services API
 */
@Injectable({
  providedIn: 'root',
})
export class OperationDefinitionServicesService extends BaseService {
  constructor(
    config: OfferApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation operationDefinitionFetch
   */
  static readonly OperationDefinitionFetchPath = '/operation-definition/{operationDefinitionUUID}';

  /**
   * Get details for one operation definition
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `operationDefinitionFetch()` instead.
   *
   * This method doesn't expect any request body.
   */
  operationDefinitionFetch$Response(params: {

    /**
     * The operation definition uuid that should be get
     */
    operationDefinitionUUID: string;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomOperationDefinitionDto>> {

    const rb = new RequestBuilder(this.rootUrl, OperationDefinitionServicesService.OperationDefinitionFetchPath, 'get');
    if (params) {
      rb.path('operationDefinitionUUID', params.operationDefinitionUUID, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomOperationDefinitionDto>;
      })
    );
  }

  /**
   * Get details for one operation definition
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `operationDefinitionFetch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  operationDefinitionFetch(params: {

    /**
     * The operation definition uuid that should be get
     */
    operationDefinitionUUID: string;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<PomOperationDefinitionDto> {

    return this.operationDefinitionFetch$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomOperationDefinitionDto>) => r.body as PomOperationDefinitionDto)
    );
  }

  /**
   * Path part for operation operationDefinitionUpdate
   */
  static readonly OperationDefinitionUpdatePath = '/operation-definition/{operationDefinitionUUID}';

  /**
   * Update operation definition with new model
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `operationDefinitionUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  operationDefinitionUpdate$Response(params: {

    /**
     * The operation definition uuid that should be get
     */
    operationDefinitionUUID: string;
    'Accept-Language'?: string;
    body: PomOperationDefinitionDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomOperationDefinitionDto>> {

    const rb = new RequestBuilder(this.rootUrl, OperationDefinitionServicesService.OperationDefinitionUpdatePath, 'put');
    if (params) {
      rb.path('operationDefinitionUUID', params.operationDefinitionUUID, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomOperationDefinitionDto>;
      })
    );
  }

  /**
   * Update operation definition with new model
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `operationDefinitionUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  operationDefinitionUpdate(params: {

    /**
     * The operation definition uuid that should be get
     */
    operationDefinitionUUID: string;
    'Accept-Language'?: string;
    body: PomOperationDefinitionDto
  },
  context?: HttpContext

): Observable<PomOperationDefinitionDto> {

    return this.operationDefinitionUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomOperationDefinitionDto>) => r.body as PomOperationDefinitionDto)
    );
  }

  /**
   * Path part for operation updateReferencesForOperationDefinition
   */
  static readonly UpdateReferencesForOperationDefinitionPath = '/operation-definition/{operationDefinitionUUID}/reference';

  /**
   * Update references for operation definition in active state
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateReferencesForOperationDefinition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReferencesForOperationDefinition$Response(params: {
    operationDefinitionUUID: string;
    body: PomOperationDefinitionReferenceRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomOperationDefinitionDto>> {

    const rb = new RequestBuilder(this.rootUrl, OperationDefinitionServicesService.UpdateReferencesForOperationDefinitionPath, 'put');
    if (params) {
      rb.path('operationDefinitionUUID', params.operationDefinitionUUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomOperationDefinitionDto>;
      })
    );
  }

  /**
   * Update references for operation definition in active state
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateReferencesForOperationDefinition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReferencesForOperationDefinition(params: {
    operationDefinitionUUID: string;
    body: PomOperationDefinitionReferenceRequestDtoDto
  },
  context?: HttpContext

): Observable<PomOperationDefinitionDto> {

    return this.updateReferencesForOperationDefinition$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomOperationDefinitionDto>) => r.body as PomOperationDefinitionDto)
    );
  }

  /**
   * Path part for operation deactivateOperationDefinition
   */
  static readonly DeactivateOperationDefinitionPath = '/operation-definition/{operationDefinitionUUID}/deactivate';

  /**
   * Deactivate operation definition with given uuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateOperationDefinition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deactivateOperationDefinition$Response(params: {
    operationDefinitionUUID: string;
    body: PomDeactivateOperationDefinitionRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ResultDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, OperationDefinitionServicesService.DeactivateOperationDefinitionPath, 'put');
    if (params) {
      rb.path('operationDefinitionUUID', params.operationDefinitionUUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultDetailsDto>;
      })
    );
  }

  /**
   * Deactivate operation definition with given uuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deactivateOperationDefinition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deactivateOperationDefinition(params: {
    operationDefinitionUUID: string;
    body: PomDeactivateOperationDefinitionRequestDtoDto
  },
  context?: HttpContext

): Observable<ResultDetailsDto> {

    return this.deactivateOperationDefinition$Response(params,context).pipe(
      map((r: StrictHttpResponse<ResultDetailsDto>) => r.body as ResultDetailsDto)
    );
  }

  /**
   * Path part for operation activateOperationDefinition
   */
  static readonly ActivateOperationDefinitionPath = '/operation-definition/{operationDefinitionUUID}/activate';

  /**
   * Activate operation with given uuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateOperationDefinition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateOperationDefinition$Response(params: {
    operationDefinitionUUID: string;
    body: PomActivateOperationDefinitionRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ResultDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, OperationDefinitionServicesService.ActivateOperationDefinitionPath, 'put');
    if (params) {
      rb.path('operationDefinitionUUID', params.operationDefinitionUUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultDetailsDto>;
      })
    );
  }

  /**
   * Activate operation with given uuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activateOperationDefinition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateOperationDefinition(params: {
    operationDefinitionUUID: string;
    body: PomActivateOperationDefinitionRequestDtoDto
  },
  context?: HttpContext

): Observable<ResultDetailsDto> {

    return this.activateOperationDefinition$Response(params,context).pipe(
      map((r: StrictHttpResponse<ResultDetailsDto>) => r.body as ResultDetailsDto)
    );
  }

  /**
   * Path part for operation operationDefinitionList
   */
  static readonly OperationDefinitionListPath = '/operation-definition';

  /**
   * Fetch list of operation definitions with optional filters.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `operationDefinitionList()` instead.
   *
   * This method doesn't expect any request body.
   */
  operationDefinitionList$Response(params: {

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * Filter that can be applied to the list of results
     */
    filter?: Array<string>;

    /**
     * Sorting that can be applied to the list of results
     */
    sort?: Array<string>;

    /**
     * Flag whether to filter roles for authorised user
     */
    filter_authorised_roles?: boolean;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomOperationDefinitionsListDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, OperationDefinitionServicesService.OperationDefinitionListPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('filter', params.filter, {});
      rb.query('sort', params.sort, {});
      rb.query('filter_authorised_roles', params.filter_authorised_roles, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomOperationDefinitionsListDtoDto>;
      })
    );
  }

  /**
   * Fetch list of operation definitions with optional filters.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `operationDefinitionList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  operationDefinitionList(params: {

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * Filter that can be applied to the list of results
     */
    filter?: Array<string>;

    /**
     * Sorting that can be applied to the list of results
     */
    sort?: Array<string>;

    /**
     * Flag whether to filter roles for authorised user
     */
    filter_authorised_roles?: boolean;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<PomOperationDefinitionsListDtoDto> {

    return this.operationDefinitionList$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomOperationDefinitionsListDtoDto>) => r.body as PomOperationDefinitionsListDtoDto)
    );
  }

  /**
   * Path part for operation operationDefinitionCreate
   */
  static readonly OperationDefinitionCreatePath = '/operation-definition';

  /**
   * Create new operation definition.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `operationDefinitionCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  operationDefinitionCreate$Response(params: {
    'Accept-Language'?: string;
    body: PomOperationDefinitionDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomOperationDefinitionDto>> {

    const rb = new RequestBuilder(this.rootUrl, OperationDefinitionServicesService.OperationDefinitionCreatePath, 'post');
    if (params) {
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomOperationDefinitionDto>;
      })
    );
  }

  /**
   * Create new operation definition.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `operationDefinitionCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  operationDefinitionCreate(params: {
    'Accept-Language'?: string;
    body: PomOperationDefinitionDto
  },
  context?: HttpContext

): Observable<PomOperationDefinitionDto> {

    return this.operationDefinitionCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomOperationDefinitionDto>) => r.body as PomOperationDefinitionDto)
    );
  }

  /**
   * Path part for operation copyActivateDefinition
   */
  static readonly CopyActivateDefinitionPath = '/operation-definition/{operationDefinitionUUID}/copy';

  /**
   * Copy operation definition with all dependencies
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyActivateDefinition()` instead.
   *
   * This method doesn't expect any request body.
   */
  copyActivateDefinition$Response(params: {

    /**
     * The operation definition uuid that should be get
     */
    operationDefinitionUUID: string;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomOperationDefinitionDto>> {

    const rb = new RequestBuilder(this.rootUrl, OperationDefinitionServicesService.CopyActivateDefinitionPath, 'post');
    if (params) {
      rb.path('operationDefinitionUUID', params.operationDefinitionUUID, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomOperationDefinitionDto>;
      })
    );
  }

  /**
   * Copy operation definition with all dependencies
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `copyActivateDefinition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  copyActivateDefinition(params: {

    /**
     * The operation definition uuid that should be get
     */
    operationDefinitionUUID: string;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<PomOperationDefinitionDto> {

    return this.copyActivateDefinition$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomOperationDefinitionDto>) => r.body as PomOperationDefinitionDto)
    );
  }

  /**
   * Path part for operation draftOperationDefinition
   */
  static readonly DraftOperationDefinitionPath = '/operation-definition/{operationDefinitionUUID}/draft';

  /**
   * Deactivate operation definition with given uuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `draftOperationDefinition()` instead.
   *
   * This method doesn't expect any request body.
   */
  draftOperationDefinition$Response(params: {
    operationDefinitionUUID: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ResultDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, OperationDefinitionServicesService.DraftOperationDefinitionPath, 'patch');
    if (params) {
      rb.path('operationDefinitionUUID', params.operationDefinitionUUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultDetailsDto>;
      })
    );
  }

  /**
   * Deactivate operation definition with given uuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `draftOperationDefinition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  draftOperationDefinition(params: {
    operationDefinitionUUID: string;
  },
  context?: HttpContext

): Observable<ResultDetailsDto> {

    return this.draftOperationDefinition$Response(params,context).pipe(
      map((r: StrictHttpResponse<ResultDetailsDto>) => r.body as ResultDetailsDto)
    );
  }

}
