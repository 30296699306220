/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { OfferApiConfiguration } from '../offer-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PomInsuranceCompaniesResponseDto } from '../models/pom-insurance-companies-response-dto';
import { PomInsuranceCompanyRequestDto } from '../models/pom-insurance-company-request-dto';
import { PomInsuranceCompanyResponseDto } from '../models/pom-insurance-company-response-dto';

@Injectable({
  providedIn: 'root',
})
export class InsuranceCompanyServiceService extends BaseService {
  constructor(
    config: OfferApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation fetch7
   */
  static readonly Fetch7Path = '/insurance-company/{id}';

  /**
   * Get details of insurance company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetch7()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetch7$Response(params: {

    /**
     * Id of Insurance Company
     */
    id: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomInsuranceCompanyResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, InsuranceCompanyServiceService.Fetch7Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomInsuranceCompanyResponseDto>;
      })
    );
  }

  /**
   * Get details of insurance company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fetch7$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetch7(params: {

    /**
     * Id of Insurance Company
     */
    id: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<PomInsuranceCompanyResponseDto> {

    return this.fetch7$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomInsuranceCompanyResponseDto>) => r.body as PomInsuranceCompanyResponseDto)
    );
  }

  /**
   * Path part for operation edit1
   */
  static readonly Edit1Path = '/insurance-company/{id}';

  /**
   * Update insurance company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `edit1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  edit1$Response(params: {

    /**
     * Id of insurance company
     */
    id: number;
    'Accept-Language'?: any;
    body: PomInsuranceCompanyRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomInsuranceCompanyResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, InsuranceCompanyServiceService.Edit1Path, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomInsuranceCompanyResponseDto>;
      })
    );
  }

  /**
   * Update insurance company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `edit1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  edit1(params: {

    /**
     * Id of insurance company
     */
    id: number;
    'Accept-Language'?: any;
    body: PomInsuranceCompanyRequestDto
  },
  context?: HttpContext

): Observable<PomInsuranceCompanyResponseDto> {

    return this.edit1$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomInsuranceCompanyResponseDto>) => r.body as PomInsuranceCompanyResponseDto)
    );
  }

  /**
   * Path part for operation list9
   */
  static readonly List9Path = '/insurance-company';

  /**
   * Get list of the insurance companies
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `list9()` instead.
   *
   * This method doesn't expect any request body.
   */
  list9$Response(params: {

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * Filter that can be applied to the list of results
     */
    filter?: Array<string>;

    /**
     * Sorting that can be applied to the list of results
     */
    sort?: Array<string>;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomInsuranceCompaniesResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, InsuranceCompanyServiceService.List9Path, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('filter', params.filter, {});
      rb.query('sort', params.sort, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomInsuranceCompaniesResponseDto>;
      })
    );
  }

  /**
   * Get list of the insurance companies
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `list9$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  list9(params: {

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * Filter that can be applied to the list of results
     */
    filter?: Array<string>;

    /**
     * Sorting that can be applied to the list of results
     */
    sort?: Array<string>;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<PomInsuranceCompaniesResponseDto> {

    return this.list9$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomInsuranceCompaniesResponseDto>) => r.body as PomInsuranceCompaniesResponseDto)
    );
  }

  /**
   * Path part for operation create7
   */
  static readonly Create7Path = '/insurance-company';

  /**
   * Create new insurance company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create7()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create7$Response(params: {
    'Accept-Language'?: any;
    body: PomInsuranceCompanyRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, InsuranceCompanyServiceService.Create7Path, 'post');
    if (params) {
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Create new insurance company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create7$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create7(params: {
    'Accept-Language'?: any;
    body: PomInsuranceCompanyRequestDto
  },
  context?: HttpContext

): Observable<number> {

    return this.create7$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

}
