import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HttpClient, HttpClientModule, provideHttpClient, withInterceptors} from '@angular/common/http';
import {authInterceptor} from './core/service/auth.interceptor';
import {AppRoutingModule} from './core/routing';
import {MyMaterialModule} from './app.material';
import {SalesApiModule} from './_api/sales/sales-api.module';
import {OfferApiModule} from './_api/offer/offer-api.module';
import {PersonRegistrationAdapterService} from './sales/insured-people/adapter/person-registration-adapter.service';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {PaymentApiModule} from './_api/payment/payment-api.module';
import {ClaimApiModule} from './_api/claim/claim-api.module';
import {CommunicationApiModule} from './_api/communication/communication-api.module';
import {DocumentsApiModule} from './_api/documents/documents-api.module';
import {PersonApiModule} from './_api/person/person-api.module';
import pl from '@angular/common/locales/pl';
import de from '@angular/common/locales/de';
import en from '@angular/common/locales/en-GB';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {NgIdleModule} from '@ng-idle/core';
import {StoreModule} from '@ngrx/store';
import {language} from './core/state/reducers/language.reducer';
import {salesToken} from './core/state/reducers/sales-token.reducer';
import {claimViewToken} from './core/state/reducers/claims-token.reducer';
import {MainAppComponent} from './core/main-app/main-app.component';

registerLocaleData(pl, 'pl');
registerLocaleData(de, 'de');
registerLocaleData(en, 'en');

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function SetLocaleFn(translateService: TranslateService): string {
    return translateService.currentLang;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    MyMaterialModule,
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    MatMomentDateModule,
    NgIdleModule.forRoot(),
    BrowserAnimationsModule,
    OfferApiModule.forRoot({ rootUrl: "api-offer/fec" }),
    PersonApiModule.forRoot({ rootUrl: "api-person/fec" }),
    SalesApiModule.forRoot({ rootUrl: "api-sales/fec" }),
    PaymentApiModule.forRoot({ rootUrl: "api-payment/fec" }),
    ClaimApiModule.forRoot({ rootUrl: "api-claim/fec" }),
    CommunicationApiModule.forRoot({ rootUrl: "api-communication/fec" }),
    DocumentsApiModule.forRoot({ rootUrl: "api-document/fec" }),
    StoreModule.forRoot({
      language: language,
      salesToken: salesToken,
      claimToken: claimViewToken,

    })
  ],
  providers: [
    provideHttpClient(
      withInterceptors([authInterceptor])
    ),
    PersonRegistrationAdapterService,
    {
      provide: LOCALE_ID,
      useFactory: SetLocaleFn,
      deps: [TranslateService]
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }


