/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ClaimApiConfiguration } from '../claim-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClmClaimInsuredObjectNamesResponseDto } from '../models/clm-claim-insured-object-names-response-dto';


/**
 * the ClaQueryInsuredObjectNames API
 */
@Injectable({
  providedIn: 'root',
})
export class ClaQueryInsuredObjectNamesService extends BaseService {
  constructor(
    config: ClaimApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getInsuredObjects
   */
  static readonly GetInsuredObjectsPath = '/claim/{claimId}/insured-object';

  /**
   * Get insured object's names.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInsuredObjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInsuredObjects$Response(params: {
    claimId: string;
    language?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClmClaimInsuredObjectNamesResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClaQueryInsuredObjectNamesService.GetInsuredObjectsPath, 'get');
    if (params) {
      rb.path('claimId', params.claimId, {});
      rb.query('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClmClaimInsuredObjectNamesResponseDto>;
      })
    );
  }

  /**
   * Get insured object's names.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInsuredObjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInsuredObjects(params: {
    claimId: string;
    language?: string;
  },
  context?: HttpContext

): Observable<ClmClaimInsuredObjectNamesResponseDto> {

    return this.getInsuredObjects$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClmClaimInsuredObjectNamesResponseDto>) => r.body as ClmClaimInsuredObjectNamesResponseDto)
    );
  }

}
