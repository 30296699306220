/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ClaimApiConfiguration } from '../claim-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClmDocumentUploadResponse } from '../models/clm-document-upload-response';


/**
 * the ClaQueryDocumentUploadURL API
 */
@Injectable({
  providedIn: 'root',
})
export class ClaQueryDocumentUploadUrlService extends BaseService {
  constructor(
    config: ClaimApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation documentUploadUrl
   */
  static readonly DocumentUploadUrlPath = '/claim/{claimId}/document/upload';

  /**
   * Request for upload URL for document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentUploadUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentUploadUrl$Response(params: {
    claimId: string;
    documentCode: string;
    fileName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClmDocumentUploadResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClaQueryDocumentUploadUrlService.DocumentUploadUrlPath, 'get');
    if (params) {
      rb.path('claimId', params.claimId, {});
      rb.query('documentCode', params.documentCode, {});
      rb.query('fileName', params.fileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClmDocumentUploadResponse>;
      })
    );
  }

  /**
   * Request for upload URL for document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentUploadUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentUploadUrl(params: {
    claimId: string;
    documentCode: string;
    fileName: string;
  },
  context?: HttpContext

): Observable<ClmDocumentUploadResponse> {

    return this.documentUploadUrl$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClmDocumentUploadResponse>) => r.body as ClmDocumentUploadResponse)
    );
  }

}
