/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { OfferApiConfiguration } from '../offer-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PomRiskObjectParametersResponseDtoDto } from '../models/pom-risk-object-parameters-response-dto-dto';


/**
 * the RiskObjectParameters API
 */
@Injectable({
  providedIn: 'root',
})
export class RiskObjectParametersService extends BaseService {
  constructor(
    config: OfferApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation riskObjectsParameters
   */
  static readonly RiskObjectsParametersPath = '/product/{productCode}/risk-object/parameters';

  /**
   * Get list of risk objects parameters by productCode
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `riskObjectsParameters()` instead.
   *
   * This method doesn't expect any request body.
   */
  riskObjectsParameters$Response(params: {
    productCode: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomRiskObjectParametersResponseDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, RiskObjectParametersService.RiskObjectsParametersPath, 'get');
    if (params) {
      rb.path('productCode', params.productCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomRiskObjectParametersResponseDtoDto>;
      })
    );
  }

  /**
   * Get list of risk objects parameters by productCode
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `riskObjectsParameters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  riskObjectsParameters(params: {
    productCode: string;
  },
  context?: HttpContext

): Observable<PomRiskObjectParametersResponseDtoDto> {

    return this.riskObjectsParameters$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomRiskObjectParametersResponseDtoDto>) => r.body as PomRiskObjectParametersResponseDtoDto)
    );
  }

}
