/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { OfferApiConfiguration } from '../offer-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PomSimpleVariantsDto } from '../models/pom-simple-variants-dto';
import { PomVariantRequestDtoDto } from '../models/pom-variant-request-dto-dto';
import { PomVariantResponseDto } from '../models/pom-variant-response-dto';

@Injectable({
  providedIn: 'root',
})
export class ProductVariantService extends BaseService {
  constructor(
    config: OfferApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation fetch3
   */
  static readonly Fetch3Path = '/offer/variant/{id}';

  /**
   * Get variant
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetch3()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetch3$Response(params: {

    /**
     * Id of the product variant
     */
    id: string;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomVariantResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductVariantService.Fetch3Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomVariantResponseDto>;
      })
    );
  }

  /**
   * Get variant
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fetch3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetch3(params: {

    /**
     * Id of the product variant
     */
    id: string;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<PomVariantResponseDto> {

    return this.fetch3$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomVariantResponseDto>) => r.body as PomVariantResponseDto)
    );
  }

  /**
   * Path part for operation update2
   */
  static readonly Update2Path = '/offer/variant/{id}';

  /**
   * Update variant
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update2$Response(params: {

    /**
     * Id of the product variant
     */
    id: string;
    'Accept-Language'?: any;
    body: PomVariantRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, ProductVariantService.Update2Path, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Update variant
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update2(params: {

    /**
     * Id of the product variant
     */
    id: string;
    'Accept-Language'?: any;
    body: PomVariantRequestDtoDto
  },
  context?: HttpContext

): Observable<number> {

    return this.update2$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation delete1
   */
  static readonly Delete1Path = '/offer/variant/{id}';

  /**
   * Delete variant
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete1()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete1$Response(params: {

    /**
     * Id of the product variant
     */
    id: string;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProductVariantService.Delete1Path, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete variant
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete1(params: {

    /**
     * Id of the product variant
     */
    id: string;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<void> {

    return this.delete1$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation create3
   */
  static readonly Create3Path = '/offer/variant';

  /**
   * Create new variant
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create3()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create3$Response(params: {
    'Accept-Language'?: any;
    body: PomVariantRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, ProductVariantService.Create3Path, 'post');
    if (params) {
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Create new variant
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create3$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create3(params: {
    'Accept-Language'?: any;
    body: PomVariantRequestDtoDto
  },
  context?: HttpContext

): Observable<number> {

    return this.create3$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation list7
   */
  static readonly List7Path = '/offer/product/{productId}/variant';

  /**
   * Get list of variants
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `list7()` instead.
   *
   * This method doesn't expect any request body.
   */
  list7$Response(params: {

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * Filter that can be applied to the list of results
     */
    filter?: Array<string>;

    /**
     * Sorting that can be applied to the list of results
     */
    sort?: Array<string>;

    /**
     * Id of the product
     */
    productId: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomSimpleVariantsDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductVariantService.List7Path, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('filter', params.filter, {});
      rb.query('sort', params.sort, {});
      rb.path('productId', params.productId, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomSimpleVariantsDto>;
      })
    );
  }

  /**
   * Get list of variants
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `list7$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  list7(params: {

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * Filter that can be applied to the list of results
     */
    filter?: Array<string>;

    /**
     * Sorting that can be applied to the list of results
     */
    sort?: Array<string>;

    /**
     * Id of the product
     */
    productId: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<PomSimpleVariantsDto> {

    return this.list7$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomSimpleVariantsDto>) => r.body as PomSimpleVariantsDto)
    );
  }

}
