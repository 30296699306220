/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { OfferApiConfiguration } from '../offer-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PomProductFormsResponseDtoDto } from '../models/pom-product-forms-response-dto-dto';


/**
 * the Forms presentation API
 */
@Injectable({
  providedIn: 'root',
})
export class FormsPresentationService extends BaseService {
  constructor(
    config: OfferApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getForms
   */
  static readonly GetFormsPath = '/product-form';

  /**
   * Get forms
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForms$Response(params: {
    productCode: string;
    variantCode?: string;
    roleType?: string;
    formCode?: string;
    languages?: Array<string>;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomProductFormsResponseDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, FormsPresentationService.GetFormsPath, 'get');
    if (params) {
      rb.query('productCode', params.productCode, {});
      rb.query('variantCode', params.variantCode, {});
      rb.query('roleType', params.roleType, {});
      rb.query('formCode', params.formCode, {});
      rb.query('languages', params.languages, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomProductFormsResponseDtoDto>;
      })
    );
  }

  /**
   * Get forms
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForms(params: {
    productCode: string;
    variantCode?: string;
    roleType?: string;
    formCode?: string;
    languages?: Array<string>;
  },
  context?: HttpContext

): Observable<PomProductFormsResponseDtoDto> {

    return this.getForms$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomProductFormsResponseDtoDto>) => r.body as PomProductFormsResponseDtoDto)
    );
  }

}
