/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { SalesApiConfiguration } from '../sales-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SamPolicyConsentRequestDtoDto } from '../models/sam-policy-consent-request-dto-dto';
import { SamPolicyConsentResponseDtoDto } from '../models/sam-policy-consent-response-dto-dto';
import { SamPolicyConsentsResponseDtoDto } from '../models/sam-policy-consents-response-dto-dto';


/**
 * the PolicyConsent API
 */
@Injectable({
  providedIn: 'root',
})
export class PolicyConsentService extends BaseService {
  constructor(
    config: SalesApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation updateConsentsData
   */
  static readonly UpdateConsentsDataPath = '/policy/{offerID}/consents';

  /**
   * Update consents data for draft.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateConsentsData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateConsentsData$Response(params: {
    offerID: string;
    'X-Authorization'?: string;
    body: SamPolicyConsentRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SamPolicyConsentResponseDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, PolicyConsentService.UpdateConsentsDataPath, 'put');
    if (params) {
      rb.path('offerID', params.offerID, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SamPolicyConsentResponseDtoDto>;
      })
    );
  }

  /**
   * Update consents data for draft.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateConsentsData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateConsentsData(params: {
    offerID: string;
    'X-Authorization'?: string;
    body: SamPolicyConsentRequestDtoDto
  },
  context?: HttpContext

): Observable<SamPolicyConsentResponseDtoDto> {

    return this.updateConsentsData$Response(params,context).pipe(
      map((r: StrictHttpResponse<SamPolicyConsentResponseDtoDto>) => r.body as SamPolicyConsentResponseDtoDto)
    );
  }

  /**
   * Path part for operation storeConsentsData
   */
  static readonly StoreConsentsDataPath = '/policy/{offerID}/consents';

  /**
   * Store consents data for policy
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeConsentsData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeConsentsData$Response(params: {
    offerID: string;
    'X-Authorization'?: string;
    body: SamPolicyConsentRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SamPolicyConsentResponseDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, PolicyConsentService.StoreConsentsDataPath, 'post');
    if (params) {
      rb.path('offerID', params.offerID, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SamPolicyConsentResponseDtoDto>;
      })
    );
  }

  /**
   * Store consents data for policy
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeConsentsData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeConsentsData(params: {
    offerID: string;
    'X-Authorization'?: string;
    body: SamPolicyConsentRequestDtoDto
  },
  context?: HttpContext

): Observable<SamPolicyConsentResponseDtoDto> {

    return this.storeConsentsData$Response(params,context).pipe(
      map((r: StrictHttpResponse<SamPolicyConsentResponseDtoDto>) => r.body as SamPolicyConsentResponseDtoDto)
    );
  }

  /**
   * Path part for operation getPolicyConsents
   */
  static readonly GetPolicyConsentsPath = '/consents';

  /**
   * Get consents
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPolicyConsents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPolicyConsents$Response(params?: {
    productCode?: string;
    'X-Authorization'?: string;
    offerID?: string;
    variantCode?: string;
    languages?: Array<string>;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SamPolicyConsentsResponseDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, PolicyConsentService.GetPolicyConsentsPath, 'get');
    if (params) {
      rb.query('productCode', params.productCode, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
      rb.query('offerID', params.offerID, {});
      rb.query('variantCode', params.variantCode, {});
      rb.query('languages', params.languages, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SamPolicyConsentsResponseDtoDto>;
      })
    );
  }

  /**
   * Get consents
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPolicyConsents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPolicyConsents(params?: {
    productCode?: string;
    'X-Authorization'?: string;
    offerID?: string;
    variantCode?: string;
    languages?: Array<string>;
  },
  context?: HttpContext

): Observable<SamPolicyConsentsResponseDtoDto> {

    return this.getPolicyConsents$Response(params,context).pipe(
      map((r: StrictHttpResponse<SamPolicyConsentsResponseDtoDto>) => r.body as SamPolicyConsentsResponseDtoDto)
    );
  }

}
