/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { OfferApiConfiguration } from '../offer-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CqmUpdateRequestDto } from '../models/cqm-update-request-dto';
import { CqmUpdateResponseDto } from '../models/cqm-update-response-dto';
import { PamOfferCalculateRequestDto } from '../models/pam-offer-calculate-request-dto';
import { PamOfferCalculateResponseDto } from '../models/pam-offer-calculate-response-dto';
import { PrmOfferOrderRequestDto } from '../models/prm-offer-order-request-dto';
import { PrmOfferOrderResponseDto } from '../models/prm-offer-order-response-dto';
import { PsmCloseRequestDto } from '../models/psm-close-request-dto';
import { PsmCloseResponseDto } from '../models/psm-close-response-dto';
import { PtmOfferCountRequestDto } from '../models/ptm-offer-count-request-dto';
import { PtmOfferCountResponseDto } from '../models/ptm-offer-count-response-dto';
import { PumOfferResultResponseDto } from '../models/pum-offer-result-response-dto';

@Injectable({
  providedIn: 'root',
})
export class OffersService extends BaseService {
  constructor(
    config: OfferApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation update10
   */
  static readonly Update10Path = '/r/platform/offer/update';

  /**
   * Update an offer. List of changes to perform is in the request.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update10()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update10$Response(params: {
    'Accept-Language'?: any;
    body: CqmUpdateRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CqmUpdateResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, OffersService.Update10Path, 'post');
    if (params) {
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CqmUpdateResponseDto>;
      })
    );
  }

  /**
   * Update an offer. List of changes to perform is in the request.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update10$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update10(params: {
    'Accept-Language'?: any;
    body: CqmUpdateRequestDto
  },
  context?: HttpContext

): Observable<CqmUpdateResponseDto> {

    return this.update10$Response(params,context).pipe(
      map((r: StrictHttpResponse<CqmUpdateResponseDto>) => r.body as CqmUpdateResponseDto)
    );
  }

  /**
   * Path part for operation order
   */
  static readonly OrderPath = '/r/platform/offer/order';

  /**
   * Place an order for the policy.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `order()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  order$Response(params: {
    'Accept-Language'?: any;
    body: PrmOfferOrderRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PrmOfferOrderResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, OffersService.OrderPath, 'post');
    if (params) {
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PrmOfferOrderResponseDto>;
      })
    );
  }

  /**
   * Place an order for the policy.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `order$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  order(params: {
    'Accept-Language'?: any;
    body: PrmOfferOrderRequestDto
  },
  context?: HttpContext

): Observable<PrmOfferOrderResponseDto> {

    return this.order$Response(params,context).pipe(
      map((r: StrictHttpResponse<PrmOfferOrderResponseDto>) => r.body as PrmOfferOrderResponseDto)
    );
  }

  /**
   * Path part for operation count
   */
  static readonly CountPath = '/r/platform/offer/count';

  /**
   * Count number of open quotations for the offer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  count$Response(params: {
    'Accept-Language'?: any;
    body: PtmOfferCountRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PtmOfferCountResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, OffersService.CountPath, 'post');
    if (params) {
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PtmOfferCountResponseDto>;
      })
    );
  }

  /**
   * Count number of open quotations for the offer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  count(params: {
    'Accept-Language'?: any;
    body: PtmOfferCountRequestDto
  },
  context?: HttpContext

): Observable<PtmOfferCountResponseDto> {

    return this.count$Response(params,context).pipe(
      map((r: StrictHttpResponse<PtmOfferCountResponseDto>) => r.body as PtmOfferCountResponseDto)
    );
  }

  /**
   * Path part for operation close
   */
  static readonly ClosePath = '/r/platform/offer/close';

  /**
   * Close the offer. During closing the service is verifying results of all sub quotations.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `close()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  close$Response(params: {
    'Accept-Language'?: any;
    body: PsmCloseRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PsmCloseResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, OffersService.ClosePath, 'post');
    if (params) {
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PsmCloseResponseDto>;
      })
    );
  }

  /**
   * Close the offer. During closing the service is verifying results of all sub quotations.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `close$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  close(params: {
    'Accept-Language'?: any;
    body: PsmCloseRequestDto
  },
  context?: HttpContext

): Observable<PsmCloseResponseDto> {

    return this.close$Response(params,context).pipe(
      map((r: StrictHttpResponse<PsmCloseResponseDto>) => r.body as PsmCloseResponseDto)
    );
  }

  /**
   * Path part for operation calculate
   */
  static readonly CalculatePath = '/r/platform/offer/calculate';

  /**
   * Perform calculations of the offer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calculate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calculate$Response(params: {
    'Accept-Language'?: any;
    body: PamOfferCalculateRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PamOfferCalculateResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, OffersService.CalculatePath, 'post');
    if (params) {
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PamOfferCalculateResponseDto>;
      })
    );
  }

  /**
   * Perform calculations of the offer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `calculate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calculate(params: {
    'Accept-Language'?: any;
    body: PamOfferCalculateRequestDto
  },
  context?: HttpContext

): Observable<PamOfferCalculateResponseDto> {

    return this.calculate$Response(params,context).pipe(
      map((r: StrictHttpResponse<PamOfferCalculateResponseDto>) => r.body as PamOfferCalculateResponseDto)
    );
  }

  /**
   * Path part for operation result
   */
  static readonly ResultPath = '/r/platform/offer/result';

  /**
   * Get result of the offer quotations.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `result()` instead.
   *
   * This method doesn't expect any request body.
   */
  result$Response(params: {

    /**
     * Offer
     */
    offer: string;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PumOfferResultResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, OffersService.ResultPath, 'get');
    if (params) {
      rb.query('offer', params.offer, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PumOfferResultResponseDto>;
      })
    );
  }

  /**
   * Get result of the offer quotations.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `result$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  result(params: {

    /**
     * Offer
     */
    offer: string;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<PumOfferResultResponseDto> {

    return this.result$Response(params,context).pipe(
      map((r: StrictHttpResponse<PumOfferResultResponseDto>) => r.body as PumOfferResultResponseDto)
    );
  }

}
