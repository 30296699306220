/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { SalesApiConfiguration } from '../sales-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SamOfferPricingDataResponseDtoDto } from '../models/sam-offer-pricing-data-response-dto-dto';


/**
 * the QueryOfferPricingData API
 */
@Injectable({
  providedIn: 'root',
})
export class QueryOfferPricingDataService extends BaseService {
  constructor(
    config: SalesApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation queryOfferPricingData
   */
  static readonly QueryOfferPricingDataPath = '/draft/{offerId}/pricing-data';

  /**
   * Read pricing data for the offer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queryOfferPricingData()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryOfferPricingData$Response(params: {
    offerId: string;
    'X-Authorization'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SamOfferPricingDataResponseDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, QueryOfferPricingDataService.QueryOfferPricingDataPath, 'get');
    if (params) {
      rb.path('offerId', params.offerId, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SamOfferPricingDataResponseDtoDto>;
      })
    );
  }

  /**
   * Read pricing data for the offer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `queryOfferPricingData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryOfferPricingData(params: {
    offerId: string;
    'X-Authorization'?: string;
  },
  context?: HttpContext

): Observable<SamOfferPricingDataResponseDtoDto> {

    return this.queryOfferPricingData$Response(params,context).pipe(
      map((r: StrictHttpResponse<SamOfferPricingDataResponseDtoDto>) => r.body as SamOfferPricingDataResponseDtoDto)
    );
  }

}
