/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { PersonApiConfiguration } from '../person-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PemBlockedPersonRequestDtoDto } from '../models/pem-blocked-person-request-dto-dto';
import { PemBlockedPersonResponseDtoDto } from '../models/pem-blocked-person-response-dto-dto';
import { PemBlockedPersonsResponseDto } from '../models/pem-blocked-persons-response-dto';
import { PemCreateBlockedPersonsResponseDto } from '../models/pem-create-blocked-persons-response-dto';
import { PemCreateBlockedResponseDto } from '../models/pem-create-blocked-response-dto';
import { PemUpdateBlockedPersonsResponseDto } from '../models/pem-update-blocked-persons-response-dto';


/**
 * the Blocked person API
 */
@Injectable({
  providedIn: 'root',
})
export class BlockedPersonService extends BaseService {
  constructor(
    config: PersonApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation blockedPersonFetch
   */
  static readonly BlockedPersonFetchPath = '/blocked/{blockedUID}';

  /**
   * Get blocked person details.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blockedPersonFetch()` instead.
   *
   * This method doesn't expect any request body.
   */
  blockedPersonFetch$Response(params: {

    /**
     * Blocked person uid
     */
    blockedUID: string;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PemBlockedPersonResponseDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, BlockedPersonService.BlockedPersonFetchPath, 'get');
    if (params) {
      rb.path('blockedUID', params.blockedUID, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PemBlockedPersonResponseDtoDto>;
      })
    );
  }

  /**
   * Get blocked person details.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `blockedPersonFetch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  blockedPersonFetch(params: {

    /**
     * Blocked person uid
     */
    blockedUID: string;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<PemBlockedPersonResponseDtoDto> {

    return this.blockedPersonFetch$Response(params,context).pipe(
      map((r: StrictHttpResponse<PemBlockedPersonResponseDtoDto>) => r.body as PemBlockedPersonResponseDtoDto)
    );
  }

  /**
   * Path part for operation blockedPersonUpdate
   */
  static readonly BlockedPersonUpdatePath = '/blocked/{blockedUID}';

  /**
   * Update blocked person.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blockedPersonUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blockedPersonUpdate$Response(params: {

    /**
     * Blocked person uid
     */
    blockedUID: string;
    'Accept-Language'?: string;
    body: PemBlockedPersonRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PemBlockedPersonResponseDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, BlockedPersonService.BlockedPersonUpdatePath, 'put');
    if (params) {
      rb.path('blockedUID', params.blockedUID, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PemBlockedPersonResponseDtoDto>;
      })
    );
  }

  /**
   * Update blocked person.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `blockedPersonUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blockedPersonUpdate(params: {

    /**
     * Blocked person uid
     */
    blockedUID: string;
    'Accept-Language'?: string;
    body: PemBlockedPersonRequestDtoDto
  },
  context?: HttpContext

): Observable<PemBlockedPersonResponseDtoDto> {

    return this.blockedPersonUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<PemBlockedPersonResponseDtoDto>) => r.body as PemBlockedPersonResponseDtoDto)
    );
  }

  /**
   * Path part for operation blockedPersonDelete
   */
  static readonly BlockedPersonDeletePath = '/blocked/{blockedUID}';

  /**
   * Removes blocked person with given uid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blockedPersonDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  blockedPersonDelete$Response(params: {

    /**
     * Blocked person uid
     */
    blockedUID: string;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BlockedPersonService.BlockedPersonDeletePath, 'delete');
    if (params) {
      rb.path('blockedUID', params.blockedUID, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes blocked person with given uid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `blockedPersonDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  blockedPersonDelete(params: {

    /**
     * Blocked person uid
     */
    blockedUID: string;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.blockedPersonDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation bulkUpdate
   */
  static readonly BulkUpdatePath = '/blocked-person';

  /**
   * Create multiple blocked persons.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkUpdate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkUpdate$Response(params?: {
    'Accept-Language'?: string;
    body?: {
'file': Blob;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PemUpdateBlockedPersonsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, BlockedPersonService.BulkUpdatePath, 'put');
    if (params) {
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PemUpdateBlockedPersonsResponseDto>;
      })
    );
  }

  /**
   * Create multiple blocked persons.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkUpdate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkUpdate(params?: {
    'Accept-Language'?: string;
    body?: {
'file': Blob;
}
  },
  context?: HttpContext

): Observable<PemUpdateBlockedPersonsResponseDto> {

    return this.bulkUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<PemUpdateBlockedPersonsResponseDto>) => r.body as PemUpdateBlockedPersonsResponseDto)
    );
  }

  /**
   * Path part for operation bulkInsert
   */
  static readonly BulkInsertPath = '/blocked-person';

  /**
   * Create multiple blocked persons.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkInsert()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkInsert$Response(params?: {
    'Accept-Language'?: string;
    body?: {
'file': Blob;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PemCreateBlockedPersonsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, BlockedPersonService.BulkInsertPath, 'post');
    if (params) {
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PemCreateBlockedPersonsResponseDto>;
      })
    );
  }

  /**
   * Create multiple blocked persons.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkInsert$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkInsert(params?: {
    'Accept-Language'?: string;
    body?: {
'file': Blob;
}
  },
  context?: HttpContext

): Observable<PemCreateBlockedPersonsResponseDto> {

    return this.bulkInsert$Response(params,context).pipe(
      map((r: StrictHttpResponse<PemCreateBlockedPersonsResponseDto>) => r.body as PemCreateBlockedPersonsResponseDto)
    );
  }

  /**
   * Path part for operation blockedPersonList
   */
  static readonly BlockedPersonListPath = '/blocked';

  /**
   * Get list of blocked person
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blockedPersonList()` instead.
   *
   * This method doesn't expect any request body.
   */
  blockedPersonList$Response(params: {

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * List will be filtered by this values
     */
    filter?: Array<string>;

    /**
     * List will be sorted by this values
     */
    sort?: Array<string>;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PemBlockedPersonsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, BlockedPersonService.BlockedPersonListPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('filter', params.filter, {});
      rb.query('sort', params.sort, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PemBlockedPersonsResponseDto>;
      })
    );
  }

  /**
   * Get list of blocked person
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `blockedPersonList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  blockedPersonList(params: {

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * List will be filtered by this values
     */
    filter?: Array<string>;

    /**
     * List will be sorted by this values
     */
    sort?: Array<string>;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<PemBlockedPersonsResponseDto> {

    return this.blockedPersonList$Response(params,context).pipe(
      map((r: StrictHttpResponse<PemBlockedPersonsResponseDto>) => r.body as PemBlockedPersonsResponseDto)
    );
  }

  /**
   * Path part for operation blockedPersonCreate
   */
  static readonly BlockedPersonCreatePath = '/blocked';

  /**
   * Create new blocked person.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blockedPersonCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blockedPersonCreate$Response(params: {
    'Accept-Language'?: string;
    body: PemBlockedPersonRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PemCreateBlockedResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, BlockedPersonService.BlockedPersonCreatePath, 'post');
    if (params) {
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PemCreateBlockedResponseDto>;
      })
    );
  }

  /**
   * Create new blocked person.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `blockedPersonCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blockedPersonCreate(params: {
    'Accept-Language'?: string;
    body: PemBlockedPersonRequestDtoDto
  },
  context?: HttpContext

): Observable<PemCreateBlockedResponseDto> {

    return this.blockedPersonCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<PemCreateBlockedResponseDto>) => r.body as PemCreateBlockedResponseDto)
    );
  }

}
