/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { SalesApiConfiguration } from '../sales-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SamCreatePolicyInsuranceDataRegistryResponseDto } from '../models/sam-create-policy-insurance-data-registry-response-dto';
import { SamPolicyInsuranceDataRegistryRequestDtoDto } from '../models/sam-policy-insurance-data-registry-request-dto-dto';
import { SamPolicyInsuranceDataRegistryResponseDto } from '../models/sam-policy-insurance-data-registry-response-dto';


/**
 * the PolicyRegistration API
 */
@Injectable({
  providedIn: 'root',
})
export class PolicyRegistrationService extends BaseService {
  constructor(
    config: SalesApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation updateRegistrationData
   */
  static readonly UpdateRegistrationDataPath = '/policy/registration/{offerID}';

  /**
   * Update new policy registration data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRegistrationData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRegistrationData$Response(params: {
    offerID: string;

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    productCode: string;
    'X-Authorization'?: string;
    variantCode?: string;
    body: SamPolicyInsuranceDataRegistryRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SamPolicyInsuranceDataRegistryResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PolicyRegistrationService.UpdateRegistrationDataPath, 'put');
    if (params) {
      rb.path('offerID', params.offerID, {});
      rb.query('caller', params.caller, {});
      rb.query('productCode', params.productCode, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
      rb.query('variantCode', params.variantCode, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SamPolicyInsuranceDataRegistryResponseDto>;
      })
    );
  }

  /**
   * Update new policy registration data
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRegistrationData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRegistrationData(params: {
    offerID: string;

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    productCode: string;
    'X-Authorization'?: string;
    variantCode?: string;
    body: SamPolicyInsuranceDataRegistryRequestDtoDto
  },
  context?: HttpContext

): Observable<SamPolicyInsuranceDataRegistryResponseDto> {

    return this.updateRegistrationData$Response(params,context).pipe(
      map((r: StrictHttpResponse<SamPolicyInsuranceDataRegistryResponseDto>) => r.body as SamPolicyInsuranceDataRegistryResponseDto)
    );
  }

  /**
   * Path part for operation createRegistrationData
   */
  static readonly CreateRegistrationDataPath = '/policy/registration';

  /**
   * Create new policy registration data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRegistrationData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRegistrationData$Response(params: {

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    productCode: string;
    variantCode?: string;
    body: SamPolicyInsuranceDataRegistryRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SamCreatePolicyInsuranceDataRegistryResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PolicyRegistrationService.CreateRegistrationDataPath, 'post');
    if (params) {
      rb.query('caller', params.caller, {});
      rb.query('productCode', params.productCode, {});
      rb.query('variantCode', params.variantCode, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SamCreatePolicyInsuranceDataRegistryResponseDto>;
      })
    );
  }

  /**
   * Create new policy registration data
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRegistrationData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRegistrationData(params: {

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    productCode: string;
    variantCode?: string;
    body: SamPolicyInsuranceDataRegistryRequestDtoDto
  },
  context?: HttpContext

): Observable<SamCreatePolicyInsuranceDataRegistryResponseDto> {

    return this.createRegistrationData$Response(params,context).pipe(
      map((r: StrictHttpResponse<SamCreatePolicyInsuranceDataRegistryResponseDto>) => r.body as SamCreatePolicyInsuranceDataRegistryResponseDto)
    );
  }

}
