/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { OfferApiConfiguration } from '../offer-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PomProductTaxResponseDtoDto } from '../models/pom-product-tax-response-dto-dto';


/**
 * the Product tax code API
 */
@Injectable({
  providedIn: 'root',
})
export class ProductTaxCodeService extends BaseService {
  constructor(
    config: OfferApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTaxCode
   */
  static readonly GetTaxCodePath = '/product/tax/{productCode}';

  /**
   * Get product tax code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaxCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxCode$Response(params: {
    productCode: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomProductTaxResponseDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductTaxCodeService.GetTaxCodePath, 'get');
    if (params) {
      rb.path('productCode', params.productCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomProductTaxResponseDtoDto>;
      })
    );
  }

  /**
   * Get product tax code
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTaxCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxCode(params: {
    productCode: string;
  },
  context?: HttpContext

): Observable<PomProductTaxResponseDtoDto> {

    return this.getTaxCode$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomProductTaxResponseDtoDto>) => r.body as PomProductTaxResponseDtoDto)
    );
  }

}
