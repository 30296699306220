/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration
 */
@Injectable({
  providedIn: 'root',
})
export class PaymentApiConfiguration {
  rootUrl: string = '/';
}

/**
 * Parameters for `PaymentApiModule.forRoot()`
 */
export interface PaymentApiConfigurationParams {
  rootUrl?: string;
}
