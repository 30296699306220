/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ClaimApiConfiguration } from '../claim-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClmClaimsDataResponseDto } from '../models/clm-claims-data-response-dto';


/**
 * the ClaQueryReadClaims API
 */
@Injectable({
  providedIn: 'root',
})
export class ClaQueryReadClaimsService extends BaseService {
  constructor(
    config: ClaimApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation claimsGet
   */
  static readonly ClaimsGetPath = '/claims';

  /**
   * Get list of claim.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `claimsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  claimsGet$Response(params: {
    page: number;
    size: number;
    filter?: Array<string>;
    sort?: Array<string>;
    tpaCode?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClmClaimsDataResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClaQueryReadClaimsService.ClaimsGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('filter', params.filter, {});
      rb.query('sort', params.sort, {});
      rb.query('tpaCode', params.tpaCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClmClaimsDataResponseDto>;
      })
    );
  }

  /**
   * Get list of claim.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `claimsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  claimsGet(params: {
    page: number;
    size: number;
    filter?: Array<string>;
    sort?: Array<string>;
    tpaCode?: string;
  },
  context?: HttpContext

): Observable<ClmClaimsDataResponseDto> {

    return this.claimsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClmClaimsDataResponseDto>) => r.body as ClmClaimsDataResponseDto)
    );
  }

}
