/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { OfferApiConfiguration } from '../offer-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CqmUpdateRequestDto } from '../models/cqm-update-request-dto';
import { CqmUpdateResponseDto } from '../models/cqm-update-response-dto';

@Injectable({
  providedIn: 'root',
})
export class QuotationsService extends BaseService {
  constructor(
    config: OfferApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation update9
   */
  static readonly Update9Path = '/r/platform/quotation/update';

  /**
   * Update quotations.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update9()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update9$Response(params: {
    'Accept-Language'?: any;
    body: CqmUpdateRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CqmUpdateResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, QuotationsService.Update9Path, 'post');
    if (params) {
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CqmUpdateResponseDto>;
      })
    );
  }

  /**
   * Update quotations.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update9$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update9(params: {
    'Accept-Language'?: any;
    body: CqmUpdateRequestDto
  },
  context?: HttpContext

): Observable<CqmUpdateResponseDto> {

    return this.update9$Response(params,context).pipe(
      map((r: StrictHttpResponse<CqmUpdateResponseDto>) => r.body as CqmUpdateResponseDto)
    );
  }

}
