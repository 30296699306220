/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClaimApiConfiguration, ClaimApiConfigurationParams } from './claim-api-configuration';

import { ClaQueryReadRolesService } from './services/cla-query-read-roles.service';
import { ClaCommandCreateRoleService } from './services/cla-command-create-role.service';
import { ClaQueryReadRisksService } from './services/cla-query-read-risks.service';
import { ClaCommandCreateRisksService } from './services/cla-command-create-risks.service';
import { ClaCommandClaimRegisterService } from './services/cla-command-claim-register.service';
import { ClaQueryReadPersonService } from './services/cla-query-read-person.service';
import { ClaCommandCreatePersonService } from './services/cla-command-create-person.service';
import { ClaimUpdateService } from './services/claim-update.service';
import { ClaQueryInsuredObjectNamesService } from './services/cla-query-insured-object-names.service';
import { ClaCommandCreateInsuredObjectService } from './services/cla-command-create-insured-object.service';
import { ClaCommandCreateIncidentService } from './services/cla-command-create-incident.service';
import { ClaQueryReadDocumentService } from './services/cla-query-read-document.service';
import { ClaCommandCreateDocumentService } from './services/cla-command-create-document.service';
import { ClaQueryConsentsService } from './services/cla-query-consents.service';
import { ClaCommandCreateConsentService } from './services/cla-command-create-consent.service';
import { ClaCommandAuthenticationVerificationService } from './services/cla-command-authentication-verification.service';
import { ClaCommandAuthenticationRefreshService } from './services/cla-command-authentication-refresh.service';
import { ClaCommandAuthenticationCodeGenerationService } from './services/cla-command-authentication-code-generation.service';
import { ClaAuthenticationFailsService } from './services/cla-authentication-fails.service';
import { ClaimRegisterService } from './services/claim-register.service';
import { ClaimInitService } from './services/claim-init.service';
import { ClaCreateClaimDocumentService } from './services/cla-create-claim-document.service';
import { ClaQueryInfoService } from './services/cla-query-info.service';
import { ClaQueryReadClaimsService } from './services/cla-query-read-claims.service';
import { ClaQueryReadClaimService } from './services/cla-query-read-claim.service';
import { ClaQueryInsuredObjectDetailsService } from './services/cla-query-insured-object-details.service';
import { ClaQueryDocumentUploadUrlService } from './services/cla-query-document-upload-url.service';
import { ClaQueryClaimantDataService } from './services/cla-query-claimant-data.service';
import { ClaQueryAuthenticationTestService } from './services/cla-query-authentication-test.service';
import { ClaQueryReadAuthenticationDetailsService } from './services/cla-query-read-authentication-details.service';
import { ClaReadDocumentsService } from './services/cla-read-documents.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ClaQueryReadRolesService,
    ClaCommandCreateRoleService,
    ClaQueryReadRisksService,
    ClaCommandCreateRisksService,
    ClaCommandClaimRegisterService,
    ClaQueryReadPersonService,
    ClaCommandCreatePersonService,
    ClaimUpdateService,
    ClaQueryInsuredObjectNamesService,
    ClaCommandCreateInsuredObjectService,
    ClaCommandCreateIncidentService,
    ClaQueryReadDocumentService,
    ClaCommandCreateDocumentService,
    ClaQueryConsentsService,
    ClaCommandCreateConsentService,
    ClaCommandAuthenticationVerificationService,
    ClaCommandAuthenticationRefreshService,
    ClaCommandAuthenticationCodeGenerationService,
    ClaAuthenticationFailsService,
    ClaimRegisterService,
    ClaimInitService,
    ClaCreateClaimDocumentService,
    ClaQueryInfoService,
    ClaQueryReadClaimsService,
    ClaQueryReadClaimService,
    ClaQueryInsuredObjectDetailsService,
    ClaQueryDocumentUploadUrlService,
    ClaQueryClaimantDataService,
    ClaQueryAuthenticationTestService,
    ClaQueryReadAuthenticationDetailsService,
    ClaReadDocumentsService,
    ClaimApiConfiguration
  ],
})
export class ClaimApiModule {
  static forRoot(params: ClaimApiConfigurationParams): ModuleWithProviders<ClaimApiModule> {
    return {
      ngModule: ClaimApiModule,
      providers: [
        {
          provide: ClaimApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ClaimApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ClaimApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
