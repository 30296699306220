/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { OfferApiConfiguration } from '../offer-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PomCategoriesResponseDto } from '../models/pom-categories-response-dto';
import { PomCategoryRequestDto } from '../models/pom-category-request-dto';
import { PomFetchCategoryResponseDto } from '../models/pom-fetch-category-response-dto';
import { PomUpdateCategoryResponseDto } from '../models/pom-update-category-response-dto';

@Injectable({
  providedIn: 'root',
})
export class ProductCategoryService extends BaseService {
  constructor(
    config: OfferApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation fetch2
   */
  static readonly Fetch2Path = '/product/category/{categoryId}';

  /**
   * Get category details.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetch2()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetch2$Response(params: {

    /**
     * Id of the category that should be get
     */
    categoryId: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomFetchCategoryResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductCategoryService.Fetch2Path, 'get');
    if (params) {
      rb.path('categoryId', params.categoryId, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomFetchCategoryResponseDto>;
      })
    );
  }

  /**
   * Get category details.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fetch2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetch2(params: {

    /**
     * Id of the category that should be get
     */
    categoryId: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<PomFetchCategoryResponseDto> {

    return this.fetch2$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomFetchCategoryResponseDto>) => r.body as PomFetchCategoryResponseDto)
    );
  }

  /**
   * Path part for operation update1
   */
  static readonly Update1Path = '/product/category/{categoryId}';

  /**
   * Update category.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update1$Response(params: {

    /**
     * Id of the category that should be updated
     */
    categoryId: number;
    'Accept-Language'?: any;
    body: PomCategoryRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomUpdateCategoryResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductCategoryService.Update1Path, 'put');
    if (params) {
      rb.path('categoryId', params.categoryId, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomUpdateCategoryResponseDto>;
      })
    );
  }

  /**
   * Update category.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update1(params: {

    /**
     * Id of the category that should be updated
     */
    categoryId: number;
    'Accept-Language'?: any;
    body: PomCategoryRequestDto
  },
  context?: HttpContext

): Observable<PomUpdateCategoryResponseDto> {

    return this.update1$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomUpdateCategoryResponseDto>) => r.body as PomUpdateCategoryResponseDto)
    );
  }

  /**
   * Path part for operation list4
   */
  static readonly List4Path = '/product/category';

  /**
   * Get list of categories
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `list4()` instead.
   *
   * This method doesn't expect any request body.
   */
  list4$Response(params: {

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * Filter that can be applied to the list of results
     */
    filter?: Array<string>;

    /**
     * Sorting that can be applied to the list of results
     */
    sort?: Array<string>;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomCategoriesResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductCategoryService.List4Path, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('filter', params.filter, {});
      rb.query('sort', params.sort, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomCategoriesResponseDto>;
      })
    );
  }

  /**
   * Get list of categories
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `list4$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  list4(params: {

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * Filter that can be applied to the list of results
     */
    filter?: Array<string>;

    /**
     * Sorting that can be applied to the list of results
     */
    sort?: Array<string>;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<PomCategoriesResponseDto> {

    return this.list4$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomCategoriesResponseDto>) => r.body as PomCategoriesResponseDto)
    );
  }

  /**
   * Path part for operation create2
   */
  static readonly Create2Path = '/product/category';

  /**
   * Create new category.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create2$Response(params: {
    'Accept-Language'?: any;
    body: PomCategoryRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomUpdateCategoryResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductCategoryService.Create2Path, 'post');
    if (params) {
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomUpdateCategoryResponseDto>;
      })
    );
  }

  /**
   * Create new category.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create2(params: {
    'Accept-Language'?: any;
    body: PomCategoryRequestDto
  },
  context?: HttpContext

): Observable<PomUpdateCategoryResponseDto> {

    return this.create2$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomUpdateCategoryResponseDto>) => r.body as PomUpdateCategoryResponseDto)
    );
  }

}
