import {CustomAction} from '../state.model';

export const SET_SALES_TOKEN = 'SET_SALES_TOKEN';
export const CLEAR_SALES_TOKEN = 'CLEAR_SALES_TOKEN';

export function salesToken(state: string = '', action: CustomAction) {
  switch (action.type) {
    case SET_SALES_TOKEN:
      return action.payload.salesToken;
    case CLEAR_SALES_TOKEN:
      return 'CLEARED';
    default:
      return state;
  }
}
