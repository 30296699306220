/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { OfferApiConfiguration } from '../offer-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PomProductRoleRequestDtoDto } from '../models/pom-product-role-request-dto-dto';
import { PomProductRoleResponseDto } from '../models/pom-product-role-response-dto';
import { PomProductRolesResponseDto } from '../models/pom-product-roles-response-dto';

@Injectable({
  providedIn: 'root',
})
export class ProductRoleService extends BaseService {
  constructor(
    config: OfferApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation fetch1
   */
  static readonly Fetch1Path = '/product/role/{id}';

  /**
   * Get details of product role
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetch1()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetch1$Response(params: {

    /**
     * Product role id
     */
    id: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomProductRoleResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductRoleService.Fetch1Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomProductRoleResponseDto>;
      })
    );
  }

  /**
   * Get details of product role
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fetch1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetch1(params: {

    /**
     * Product role id
     */
    id: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<PomProductRoleResponseDto> {

    return this.fetch1$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomProductRoleResponseDto>) => r.body as PomProductRoleResponseDto)
    );
  }

  /**
   * Path part for operation edit
   */
  static readonly EditPath = '/product/role/{id}';

  /**
   * Update product role
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `edit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  edit$Response(params: {

    /**
     * Product role id
     */
    id: number;
    'Accept-Language'?: any;
    body: PomProductRoleRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, ProductRoleService.EditPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Update product role
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `edit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  edit(params: {

    /**
     * Product role id
     */
    id: number;
    'Accept-Language'?: any;
    body: PomProductRoleRequestDtoDto
  },
  context?: HttpContext

): Observable<number> {

    return this.edit$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation remove
   */
  static readonly RemovePath = '/product/role/{id}';

  /**
   * Remove product role
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `remove()` instead.
   *
   * This method doesn't expect any request body.
   */
  remove$Response(params: {

    /**
     * Product role id
     */
    id: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProductRoleService.RemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Remove product role
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `remove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  remove(params: {

    /**
     * Product role id
     */
    id: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<void> {

    return this.remove$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation create1
   */
  static readonly Create1Path = '/product/role';

  /**
   * Create new product role
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create1$Response(params: {
    'Accept-Language'?: any;
    body: PomProductRoleRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, ProductRoleService.Create1Path, 'post');
    if (params) {
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Create new product role
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create1(params: {
    'Accept-Language'?: any;
    body: PomProductRoleRequestDtoDto
  },
  context?: HttpContext

): Observable<number> {

    return this.create1$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation list
   */
  static readonly ListPath = '/product/{productId}/role';

  /**
   * Get list of the product roles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `list()` instead.
   *
   * This method doesn't expect any request body.
   */
  list$Response(params: {

    /**
     * Product id
     */
    productId: number;

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * Filter that can be applied to the list of results
     */
    filter?: Array<string>;

    /**
     * Sorting that can be applied to the list of results
     */
    sort?: Array<string>;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomProductRolesResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductRoleService.ListPath, 'get');
    if (params) {
      rb.path('productId', params.productId, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('filter', params.filter, {});
      rb.query('sort', params.sort, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomProductRolesResponseDto>;
      })
    );
  }

  /**
   * Get list of the product roles
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `list$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  list(params: {

    /**
     * Product id
     */
    productId: number;

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * Filter that can be applied to the list of results
     */
    filter?: Array<string>;

    /**
     * Sorting that can be applied to the list of results
     */
    sort?: Array<string>;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<PomProductRolesResponseDto> {

    return this.list$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomProductRolesResponseDto>) => r.body as PomProductRolesResponseDto)
    );
  }

}
