import {CustomAction} from '../state.model';

export const SET_LANGUAGE = 'SET_LANGUAGE';

export function language(lng: string, action: CustomAction) {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.payload.language;
    default:
      return lng;
  }
}
