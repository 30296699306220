/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ClaimApiConfiguration } from '../claim-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClmClaimExternalDocumentsResponseDto } from '../models/clm-claim-external-documents-response-dto';


/**
 * the ClaReadDocuments API
 */
@Injectable({
  providedIn: 'root',
})
export class ClaReadDocumentsService extends BaseService {
  constructor(
    config: ClaimApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation claimExternalDocumentsGet
   */
  static readonly ClaimExternalDocumentsGetPath = '/claim/external/documents';

  /**
   * Get documents by external claim.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `claimExternalDocumentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  claimExternalDocumentsGet$Response(params?: {
    tpaCode?: string;
    claimId?: string;
    claimNumber?: string;
    externalClaimNumber?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClmClaimExternalDocumentsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClaReadDocumentsService.ClaimExternalDocumentsGetPath, 'get');
    if (params) {
      rb.query('tpaCode', params.tpaCode, {});
      rb.query('claimId', params.claimId, {});
      rb.query('claimNumber', params.claimNumber, {});
      rb.query('externalClaimNumber', params.externalClaimNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClmClaimExternalDocumentsResponseDto>;
      })
    );
  }

  /**
   * Get documents by external claim.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `claimExternalDocumentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  claimExternalDocumentsGet(params?: {
    tpaCode?: string;
    claimId?: string;
    claimNumber?: string;
    externalClaimNumber?: string;
  },
  context?: HttpContext

): Observable<ClmClaimExternalDocumentsResponseDto> {

    return this.claimExternalDocumentsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClmClaimExternalDocumentsResponseDto>) => r.body as ClmClaimExternalDocumentsResponseDto)
    );
  }

}
