/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { OfferApiConfiguration } from '../offer-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PomProductsPresentationResponseDtoDto } from '../models/pom-products-presentation-response-dto-dto';

@Injectable({
  providedIn: 'root',
})
export class ProductPresentationService extends BaseService {
  constructor(
    config: OfferApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation list5
   */
  static readonly List5Path = '/product-presentation';

  /**
   * Get list of products
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `list5()` instead.
   *
   * This method doesn't expect any request body.
   */
  list5$Response(params: {

    /**
     * Partner code
     */
    partnerCode: string;

    /**
     * Employee code
     */
    employeeCode?: string;

    /**
     * List of categories
     */
    categories?: Array<string>;

    /**
     * List of products
     */
    products?: Array<string>;

    /**
     * List of languages
     */
    languages?: Array<string>;

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomProductsPresentationResponseDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductPresentationService.List5Path, 'get');
    if (params) {
      rb.query('partnerCode', params.partnerCode, {});
      rb.query('employeeCode', params.employeeCode, {});
      rb.query('categories', params.categories, {});
      rb.query('products', params.products, {});
      rb.query('languages', params.languages, {});
      rb.query('caller', params.caller, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomProductsPresentationResponseDtoDto>;
      })
    );
  }

  /**
   * Get list of products
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `list5$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  list5(params: {

    /**
     * Partner code
     */
    partnerCode: string;

    /**
     * Employee code
     */
    employeeCode?: string;

    /**
     * List of categories
     */
    categories?: Array<string>;

    /**
     * List of products
     */
    products?: Array<string>;

    /**
     * List of languages
     */
    languages?: Array<string>;

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
  },
  context?: HttpContext

): Observable<PomProductsPresentationResponseDtoDto> {

    return this.list5$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomProductsPresentationResponseDtoDto>) => r.body as PomProductsPresentationResponseDtoDto)
    );
  }

}
