/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { OfferApiConfiguration } from '../offer-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PomGtcRequestDto } from '../models/pom-gtc-request-dto';
import { PomGtcResponseDto } from '../models/pom-gtc-response-dto';
import { PomGtcsResponseDto } from '../models/pom-gtcs-response-dto';

@Injectable({
  providedIn: 'root',
})
export class ProductGtcService extends BaseService {
  constructor(
    config: OfferApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation fetch
   */
  static readonly FetchPath = '/product/{productId}/gtc/{id}';

  /**
   * Get gtc
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetch()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetch$Response(params: {

    /**
     * Id of the gtc
     */
    id: number;

    /**
     * Id of the product
     */
    productId: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomGtcResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductGtcService.FetchPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('productId', params.productId, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomGtcResponseDto>;
      })
    );
  }

  /**
   * Get gtc
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fetch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetch(params: {

    /**
     * Id of the gtc
     */
    id: number;

    /**
     * Id of the product
     */
    productId: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<PomGtcResponseDto> {

    return this.fetch$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomGtcResponseDto>) => r.body as PomGtcResponseDto)
    );
  }

  /**
   * Path part for operation update
   */
  static readonly UpdatePath = '/product/{productId}/gtc/{id}';

  /**
   * Update gtc
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(params: {

    /**
     * Id of the gtc
     */
    id: number;

    /**
     * Id of the product
     */
    productId: number;
    body: PomGtcRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProductGtcService.UpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('productId', params.productId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update gtc
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(params: {

    /**
     * Id of the gtc
     */
    id: number;

    /**
     * Id of the product
     */
    productId: number;
    body: PomGtcRequestDto
  },
  context?: HttpContext

): Observable<void> {

    return this.update$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation delete
   */
  static readonly DeletePath = '/product/{productId}/gtc/{id}';

  /**
   * Delete gtc
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: {

    /**
     * Id of the gtc
     */
    id: number;

    /**
     * Id of the product
     */
    productId: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProductGtcService.DeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('productId', params.productId, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete gtc
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: {

    /**
     * Id of the gtc
     */
    id: number;

    /**
     * Id of the product
     */
    productId: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<void> {

    return this.delete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation list2
   */
  static readonly List2Path = '/product/{productId}/gtc';

  /**
   * Get list of gtc
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `list2()` instead.
   *
   * This method doesn't expect any request body.
   */
  list2$Response(params: {

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * Sorting that can be applied to the list of results
     */
    sort?: Array<string>;

    /**
     * Id of the product
     */
    productId: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomGtcsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductGtcService.List2Path, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('sort', params.sort, {});
      rb.path('productId', params.productId, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomGtcsResponseDto>;
      })
    );
  }

  /**
   * Get list of gtc
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `list2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  list2(params: {

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * Sorting that can be applied to the list of results
     */
    sort?: Array<string>;

    /**
     * Id of the product
     */
    productId: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<PomGtcsResponseDto> {

    return this.list2$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomGtcsResponseDto>) => r.body as PomGtcsResponseDto)
    );
  }

  /**
   * Path part for operation create
   */
  static readonly CreatePath = '/product/{productId}/gtc';

  /**
   * Create new gtc
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: {

    /**
     * Id of the product
     */
    productId: number;
    'Accept-Language'?: any;
    body: PomGtcRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, ProductGtcService.CreatePath, 'post');
    if (params) {
      rb.path('productId', params.productId, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Create new gtc
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: {

    /**
     * Id of the product
     */
    productId: number;
    'Accept-Language'?: any;
    body: PomGtcRequestDto
  },
  context?: HttpContext

): Observable<number> {

    return this.create$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

}
