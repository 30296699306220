/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ClaimApiConfiguration } from '../claim-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClmClaimInitRequestDto } from '../models/clm-claim-init-request-dto';
import { ClmClaimInitResponseDto } from '../models/clm-claim-init-response-dto';


/**
 * the ClaimInit API
 */
@Injectable({
  providedIn: 'root',
})
export class ClaimInitService extends BaseService {
  constructor(
    config: ClaimApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation claimInitCommand
   */
  static readonly ClaimInitCommandPath = '/claim/init';

  /**
   * Initialize claim with basic data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `claimInitCommand()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  claimInitCommand$Response(params: {
    body: ClmClaimInitRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClmClaimInitResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClaimInitService.ClaimInitCommandPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClmClaimInitResponseDto>;
      })
    );
  }

  /**
   * Initialize claim with basic data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `claimInitCommand$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  claimInitCommand(params: {
    body: ClmClaimInitRequestDto
  },
  context?: HttpContext

): Observable<ClmClaimInitResponseDto> {

    return this.claimInitCommand$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClmClaimInitResponseDto>) => r.body as ClmClaimInitResponseDto)
    );
  }

}
