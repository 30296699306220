import {CustomAction} from '../state.model';

export const SET_CLAIM_VIEW_TOKEN = 'SET_CLAIM_VIEW_TOKEN';
export const CLEAR_CLAIM_VIEW_TOKEN = 'CLEAR_CLAIM_VIEW_TOKEN';


export function claimViewToken(state: string = '', action: CustomAction) {
  switch (action.type) {
    case SET_CLAIM_VIEW_TOKEN:
      return action.payload.claimViewToken;
    case CLEAR_CLAIM_VIEW_TOKEN:
      return 'CLEARED';
    default:
      return state;
  }
}
