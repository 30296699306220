/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { PersonApiConfiguration } from '../person-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PemCreatePersonResponseDto } from '../models/pem-create-person-response-dto';
import { PemPersonRequestDtoDto } from '../models/pem-person-request-dto-dto';
import { PemPersonResponseDto } from '../models/pem-person-response-dto';
import { PemPersonsResponseDto } from '../models/pem-persons-response-dto';


/**
 * the Person API
 */
@Injectable({
  providedIn: 'root',
})
export class PersonService extends BaseService {
  constructor(
    config: PersonApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation personFetch
   */
  static readonly PersonFetchPath = '/person/{personUID}';

  /**
   * Get person details.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personFetch()` instead.
   *
   * This method doesn't expect any request body.
   */
  personFetch$Response(params: {

    /**
     * Person unique id
     */
    personUID: string;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PemPersonResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PersonService.PersonFetchPath, 'get');
    if (params) {
      rb.path('personUID', params.personUID, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PemPersonResponseDto>;
      })
    );
  }

  /**
   * Get person details.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `personFetch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personFetch(params: {

    /**
     * Person unique id
     */
    personUID: string;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<PemPersonResponseDto> {

    return this.personFetch$Response(params,context).pipe(
      map((r: StrictHttpResponse<PemPersonResponseDto>) => r.body as PemPersonResponseDto)
    );
  }

  /**
   * Path part for operation personUpdate
   */
  static readonly PersonUpdatePath = '/person/{personUID}';

  /**
   * Update person.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  personUpdate$Response(params: {

    /**
     * Person unique id
     */
    personUID: string;
    'Accept-Language'?: string;
    body: PemPersonRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PemPersonResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PersonService.PersonUpdatePath, 'put');
    if (params) {
      rb.path('personUID', params.personUID, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PemPersonResponseDto>;
      })
    );
  }

  /**
   * Update person.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `personUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  personUpdate(params: {

    /**
     * Person unique id
     */
    personUID: string;
    'Accept-Language'?: string;
    body: PemPersonRequestDtoDto
  },
  context?: HttpContext

): Observable<PemPersonResponseDto> {

    return this.personUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<PemPersonResponseDto>) => r.body as PemPersonResponseDto)
    );
  }

  /**
   * Path part for operation personList
   */
  static readonly PersonListPath = '/person';

  /**
   * Get list of all persons
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personList()` instead.
   *
   * This method doesn't expect any request body.
   */
  personList$Response(params: {

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * List will be filtered by this values
     */
    filter?: Array<string>;

    /**
     * List will be sorted by this values
     */
    sort?: Array<string>;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PemPersonsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PersonService.PersonListPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('filter', params.filter, {});
      rb.query('sort', params.sort, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PemPersonsResponseDto>;
      })
    );
  }

  /**
   * Get list of all persons
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `personList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personList(params: {

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * List will be filtered by this values
     */
    filter?: Array<string>;

    /**
     * List will be sorted by this values
     */
    sort?: Array<string>;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<PemPersonsResponseDto> {

    return this.personList$Response(params,context).pipe(
      map((r: StrictHttpResponse<PemPersonsResponseDto>) => r.body as PemPersonsResponseDto)
    );
  }

  /**
   * Path part for operation personCreate
   */
  static readonly PersonCreatePath = '/person';

  /**
   * Create new person.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  personCreate$Response(params: {
    'Accept-Language'?: string;
    body: PemPersonRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PemCreatePersonResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PersonService.PersonCreatePath, 'post');
    if (params) {
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PemCreatePersonResponseDto>;
      })
    );
  }

  /**
   * Create new person.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `personCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  personCreate(params: {
    'Accept-Language'?: string;
    body: PemPersonRequestDtoDto
  },
  context?: HttpContext

): Observable<PemCreatePersonResponseDto> {

    return this.personCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<PemCreatePersonResponseDto>) => r.body as PemCreatePersonResponseDto)
    );
  }

  /**
   * Path part for operation personFetchAuthenticated
   */
  static readonly PersonFetchAuthenticatedPath = '/person/{personUID}/authenticated';

  /**
   * Get person details.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personFetchAuthenticated()` instead.
   *
   * This method doesn't expect any request body.
   */
  personFetchAuthenticated$Response(params: {

    /**
     * Person unique id
     */
    personUID: string;
    'X-Authorization': string;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PemPersonResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PersonService.PersonFetchAuthenticatedPath, 'get');
    if (params) {
      rb.path('personUID', params.personUID, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PemPersonResponseDto>;
      })
    );
  }

  /**
   * Get person details.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `personFetchAuthenticated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personFetchAuthenticated(params: {

    /**
     * Person unique id
     */
    personUID: string;
    'X-Authorization': string;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<PemPersonResponseDto> {

    return this.personFetchAuthenticated$Response(params,context).pipe(
      map((r: StrictHttpResponse<PemPersonResponseDto>) => r.body as PemPersonResponseDto)
    );
  }

}
