/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SalesApiConfiguration, SalesApiConfigurationParams } from './sales-api-configuration';

import { PolicyFormService } from './services/policy-form.service';
import { PolicyConsentService } from './services/policy-consent.service';
import { PolicyRegistrationService } from './services/policy-registration.service';
import { PolicyCompositeRegistrationService } from './services/policy-composite-registration.service';
import { PolicyComposeRegisterService } from './services/policy-compose-register.service';
import { PersonRegistrationService } from './services/person-registration.service';
import { InsuredObjectDraftService } from './services/insured-object-draft.service';
import { PolicyDraftService } from './services/policy-draft.service';
import { SaaCommandAuthenticationRefreshService } from './services/saa-command-authentication-refresh.service';
import { ProductPresentationService } from './services/product-presentation.service';
import { PolicyRequiredFieldsService } from './services/policy-required-fields.service';
import { QueryOfferProductDetailsService } from './services/query-offer-product-details.service';
import { QueryOfferPricingDataService } from './services/query-offer-pricing-data.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    PolicyFormService,
    PolicyConsentService,
    PolicyRegistrationService,
    PolicyCompositeRegistrationService,
    PolicyComposeRegisterService,
    PersonRegistrationService,
    InsuredObjectDraftService,
    PolicyDraftService,
    SaaCommandAuthenticationRefreshService,
    ProductPresentationService,
    PolicyRequiredFieldsService,
    QueryOfferProductDetailsService,
    QueryOfferPricingDataService,
    SalesApiConfiguration
  ],
})
export class SalesApiModule {
  static forRoot(params: SalesApiConfigurationParams): ModuleWithProviders<SalesApiModule> {
    return {
      ngModule: SalesApiModule,
      providers: [
        {
          provide: SalesApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: SalesApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('SalesApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
