import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always'
};

const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main-app/main-app.routes').then(m => m.MAIN_APP_ROUTES),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, routingConfiguration)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
