/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ClaimApiConfiguration } from '../claim-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClmConsentsResponseDto } from '../models/clm-consents-response-dto';


/**
 * the ClaQueryConsents API
 */
@Injectable({
  providedIn: 'root',
})
export class ClaQueryConsentsService extends BaseService {
  constructor(
    config: ClaimApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getConsents
   */
  static readonly GetConsentsPath = '/claim/{claimId}/consent';

  /**
   * Get consents.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsents$Response(params: {
    claimId: string;
    language?: 'PL' | 'DE' | 'EN';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClmConsentsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClaQueryConsentsService.GetConsentsPath, 'get');
    if (params) {
      rb.path('claimId', params.claimId, {});
      rb.query('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClmConsentsResponseDto>;
      })
    );
  }

  /**
   * Get consents.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsents(params: {
    claimId: string;
    language?: 'PL' | 'DE' | 'EN';
  },
  context?: HttpContext

): Observable<ClmConsentsResponseDto> {

    return this.getConsents$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClmConsentsResponseDto>) => r.body as ClmConsentsResponseDto)
    );
  }

}
