/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { SalesApiConfiguration } from '../sales-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SamVariantPresentationResponseDto } from '../models/sam-variant-presentation-response-dto';


/**
 * the ProductPresentation API
 */
@Injectable({
  providedIn: 'root',
})
export class ProductPresentationService extends BaseService {
  constructor(
    config: SalesApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation productVariants
   */
  static readonly ProductVariantsPath = '/product/{productCode}/variants';

  /**
   * Get policy required fields
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productVariants()` instead.
   *
   * This method doesn't expect any request body.
   */
  productVariants$Response(params: {

    /**
     * Product code
     */
    productCode: string;

    /**
     * partnerCode
     */
    partnerCode: string;
    languages?: Array<string>;
    'X-Authorization'?: string;
    offerId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SamVariantPresentationResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProductPresentationService.ProductVariantsPath, 'get');
    if (params) {
      rb.path('productCode', params.productCode, {});
      rb.query('partnerCode', params.partnerCode, {});
      rb.query('languages', params.languages, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
      rb.query('offerId', params.offerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SamVariantPresentationResponseDto>;
      })
    );
  }

  /**
   * Get policy required fields
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `productVariants$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productVariants(params: {

    /**
     * Product code
     */
    productCode: string;

    /**
     * partnerCode
     */
    partnerCode: string;
    languages?: Array<string>;
    'X-Authorization'?: string;
    offerId?: string;
  },
  context?: HttpContext

): Observable<SamVariantPresentationResponseDto> {

    return this.productVariants$Response(params,context).pipe(
      map((r: StrictHttpResponse<SamVariantPresentationResponseDto>) => r.body as SamVariantPresentationResponseDto)
    );
  }

}
