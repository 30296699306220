/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { SalesApiConfiguration } from '../sales-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SamInsuredObjectRequestDtoDto } from '../models/sam-insured-object-request-dto-dto';
import { SamInsuredObjectResponseDtoDto } from '../models/sam-insured-object-response-dto-dto';
import { SamOfferIdResponseDto } from '../models/sam-offer-id-response-dto';


/**
 * the Insured object draft API
 */
@Injectable({
  providedIn: 'root',
})
export class InsuredObjectDraftService extends BaseService {
  constructor(
    config: SalesApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation insuredObjectUpdate
   */
  static readonly InsuredObjectUpdatePath = '/insured-object';

  /**
   * Update insured object for the offer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insuredObjectUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insuredObjectUpdate$Response(params: {

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    'X-Authorization'?: string;
    body: SamInsuredObjectRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SamOfferIdResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, InsuredObjectDraftService.InsuredObjectUpdatePath, 'put');
    if (params) {
      rb.query('caller', params.caller, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SamOfferIdResponseDto>;
      })
    );
  }

  /**
   * Update insured object for the offer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `insuredObjectUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insuredObjectUpdate(params: {

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    'X-Authorization'?: string;
    body: SamInsuredObjectRequestDtoDto
  },
  context?: HttpContext

): Observable<SamOfferIdResponseDto> {

    return this.insuredObjectUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<SamOfferIdResponseDto>) => r.body as SamOfferIdResponseDto)
    );
  }

  /**
   * Path part for operation insuredObjectCreate
   */
  static readonly InsuredObjectCreatePath = '/insured-object';

  /**
   * Create new insured object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insuredObjectCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insuredObjectCreate$Response(params: {

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    'X-Authorization'?: string;
    body: SamInsuredObjectRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SamOfferIdResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, InsuredObjectDraftService.InsuredObjectCreatePath, 'post');
    if (params) {
      rb.query('caller', params.caller, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SamOfferIdResponseDto>;
      })
    );
  }

  /**
   * Create new insured object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `insuredObjectCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insuredObjectCreate(params: {

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    'X-Authorization'?: string;
    body: SamInsuredObjectRequestDtoDto
  },
  context?: HttpContext

): Observable<SamOfferIdResponseDto> {

    return this.insuredObjectCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<SamOfferIdResponseDto>) => r.body as SamOfferIdResponseDto)
    );
  }

  /**
   * Path part for operation insuredObjectFetch
   */
  static readonly InsuredObjectFetchPath = '/insured-object/{productCode}/{partnerCode}';

  /**
   * Return the definition of risk objects for selected product
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insuredObjectFetch()` instead.
   *
   * This method doesn't expect any request body.
   */
  insuredObjectFetch$Response(params: {
    productCode: string;
    partnerCode: string;

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    'X-Authorization'?: string;

    /**
     * Offer Id (if known)
     */
    offerId?: string;

    /**
     * Code of the employee of the partner
     */
    employeeCode?: string;

    /**
     * Code of the variant
     */
    variantCode?: string;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SamInsuredObjectResponseDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, InsuredObjectDraftService.InsuredObjectFetchPath, 'get');
    if (params) {
      rb.path('productCode', params.productCode, {});
      rb.path('partnerCode', params.partnerCode, {});
      rb.query('caller', params.caller, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
      rb.query('offerId', params.offerId, {});
      rb.query('employeeCode', params.employeeCode, {});
      rb.query('variantCode', params.variantCode, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SamInsuredObjectResponseDtoDto>;
      })
    );
  }

  /**
   * Return the definition of risk objects for selected product
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `insuredObjectFetch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  insuredObjectFetch(params: {
    productCode: string;
    partnerCode: string;

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    'X-Authorization'?: string;

    /**
     * Offer Id (if known)
     */
    offerId?: string;

    /**
     * Code of the employee of the partner
     */
    employeeCode?: string;

    /**
     * Code of the variant
     */
    variantCode?: string;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<SamInsuredObjectResponseDtoDto> {

    return this.insuredObjectFetch$Response(params,context).pipe(
      map((r: StrictHttpResponse<SamInsuredObjectResponseDtoDto>) => r.body as SamInsuredObjectResponseDtoDto)
    );
  }

}
