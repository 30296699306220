import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AppState} from './core/state/app-state';
import {Store} from '@ngrx/store';
import {CookieService} from 'ngx-cookie-service';
import {isNullOrUndefined} from 'is-what';
import {SET_LANGUAGE} from './core/state/reducers/language.reducer';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-root',
  template: ` <router-outlet></router-outlet> `,
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  languageSub$: Subscription;

  constructor(
    private _translate: TranslateService,
    private store: Store<AppState>,
    private _cookieService: CookieService,
    private router: Router,
  ) {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0);
        }
      });

    store.select('language').subscribe((language: string) => {});
  }

  ngOnInit(): void {

    this._translate.addLangs(['pl', 'en', 'de']);
    this.languageSub$ = this.store.select('language').subscribe((lng: 'pl' | 'en' | 'de') => {
      if (!lng) {
        this.seti18n();
      } else {
        this._translate.setDefaultLang(lng);
        this._translate.use(lng);
        this.storeLanguageInCookie(lng);
      }
    });
  }

  private seti18n(): void {
    const storedLng = this._cookieService.get('USER_LNG');

    let browserLang = this.getBrowserLocales({languageCodeOnly: true})
    if (browserLang.length > 0 && ['pl', 'en', 'de'].includes(browserLang[0])) {
      this._translate.setDefaultLang(browserLang[0]);
      this._translate.use(browserLang[0]);
    } else {
      this._translate.setDefaultLang('en');
      this._translate.use('en');
    }

    let evaluatedLng = this._translate.getDefaultLang();
    if (!isNullOrUndefined(storedLng) && storedLng.length > 0) {
      evaluatedLng = storedLng;
    }

    this.storeLanguageInCookie(evaluatedLng);

    this.store.dispatch({
      type: SET_LANGUAGE,
      payload: {
        language: evaluatedLng,
      },
    });
  }

  storeLanguageInCookie(lang: string) {
    if (!this._cookieService.check('USER_LNG')) {
      this._cookieService.set('USER_LNG', lang, 7, '/');
    }
  }

  getBrowserLocales(options = {}): string[] {
    const defaultOptions = {
      languageCodeOnly: false,
    };
    const opt = {
      ...defaultOptions,
      ...options,
    };
    const browserLocales =
      navigator.languages === undefined
        ? [navigator.language]
        : navigator.languages;
    if (!browserLocales) {
      return undefined;
    }
    return browserLocales.map(locale => {
      const trimmedLocale = locale.trim();
      return opt.languageCodeOnly
        ? trimmedLocale.split(/-|_/)[0]
        : trimmedLocale;
    });
  }
}
