/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ClaimApiConfiguration } from '../claim-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClaimClaimIdAuthenticationFailsPost200ResponseDto } from '../models/claim-claim-id-authentication-fails-post-200-response-dto';
import { ClmAuthenticationFailsResponseDto } from '../models/clm-authentication-fails-response-dto';


/**
 * the ClaAuthenticationFails API
 */
@Injectable({
  providedIn: 'root',
})
export class ClaAuthenticationFailsService extends BaseService {
  constructor(
    config: ClaimApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation claimClaimIdAuthenticationFailsGet
   */
  static readonly ClaimClaimIdAuthenticationFailsGetPath = '/claim/{claimId}/authentication/fails';

  /**
   * Get information about authentication failed attempts threshold exceeded.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `claimClaimIdAuthenticationFailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  claimClaimIdAuthenticationFailsGet$Response(params: {
    claimId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClmAuthenticationFailsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClaAuthenticationFailsService.ClaimClaimIdAuthenticationFailsGetPath, 'get');
    if (params) {
      rb.path('claimId', params.claimId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClmAuthenticationFailsResponseDto>;
      })
    );
  }

  /**
   * Get information about authentication failed attempts threshold exceeded.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `claimClaimIdAuthenticationFailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  claimClaimIdAuthenticationFailsGet(params: {
    claimId: string;
  },
  context?: HttpContext

): Observable<ClmAuthenticationFailsResponseDto> {

    return this.claimClaimIdAuthenticationFailsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClmAuthenticationFailsResponseDto>) => r.body as ClmAuthenticationFailsResponseDto)
    );
  }

  /**
   * Path part for operation claimClaimIdAuthenticationFailsPost
   */
  static readonly ClaimClaimIdAuthenticationFailsPostPath = '/claim/{claimId}/authentication/fails';

  /**
   * Increment counter of authentication failed attempts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `claimClaimIdAuthenticationFailsPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  claimClaimIdAuthenticationFailsPost$Response(params: {
    claimId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClaimClaimIdAuthenticationFailsPost200ResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClaAuthenticationFailsService.ClaimClaimIdAuthenticationFailsPostPath, 'post');
    if (params) {
      rb.path('claimId', params.claimId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClaimClaimIdAuthenticationFailsPost200ResponseDto>;
      })
    );
  }

  /**
   * Increment counter of authentication failed attempts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `claimClaimIdAuthenticationFailsPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  claimClaimIdAuthenticationFailsPost(params: {
    claimId: string;
  },
  context?: HttpContext

): Observable<ClaimClaimIdAuthenticationFailsPost200ResponseDto> {

    return this.claimClaimIdAuthenticationFailsPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClaimClaimIdAuthenticationFailsPost200ResponseDto>) => r.body as ClaimClaimIdAuthenticationFailsPost200ResponseDto)
    );
  }

}
