/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { SalesApiConfiguration } from '../sales-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AcceptOffer200ResponseDtoDto } from '../models/accept-offer-200-response-dto-dto';
import { SamAcceptRequestDto } from '../models/sam-accept-request-dto';
import { SamCardDetailsResponseDtoDto } from '../models/sam-card-details-response-dto-dto';
import { SamDraftConfirmRequestDtoDto } from '../models/sam-draft-confirm-request-dto-dto';
import { SamDraftConfirmResponseDtoDto } from '../models/sam-draft-confirm-response-dto-dto';
import { SamDraftRequestDtoDto } from '../models/sam-draft-request-dto-dto';
import { SamDraftResponseDtoDto } from '../models/sam-draft-response-dto-dto';
import { SamIssueRequestDto } from '../models/sam-issue-request-dto';
import { SamIssueResponseDto } from '../models/sam-issue-response-dto';
import { SamOfferCardDetailsRequestDtoDto } from '../models/sam-offer-card-details-request-dto-dto';
import { SamOfferPaymentDetailsRequestDtoDto } from '../models/sam-offer-payment-details-request-dto-dto';
import { SamOfferSummaryResponseDtoDto } from '../models/sam-offer-summary-response-dto-dto';
import { SamOfferVariantRequestDtoDto } from '../models/sam-offer-variant-request-dto-dto';
import { SamPaymentDetailsResponseDtoDto } from '../models/sam-payment-details-response-dto-dto';


/**
 * the Policy Draft API
 */
@Injectable({
  providedIn: 'root',
})
export class PolicyDraftService extends BaseService {
  constructor(
    config: SalesApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation updateOfferWithVariant
   */
  static readonly UpdateOfferWithVariantPath = '/draft/{offerId}/variant';

  /**
   * Update policy draft about variant information
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOfferWithVariant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOfferWithVariant$Response(params: {
    offerId: string;
    'X-Authorization'?: string;
    body: SamOfferVariantRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SamDraftResponseDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, PolicyDraftService.UpdateOfferWithVariantPath, 'put');
    if (params) {
      rb.path('offerId', params.offerId, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SamDraftResponseDtoDto>;
      })
    );
  }

  /**
   * Update policy draft about variant information
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOfferWithVariant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOfferWithVariant(params: {
    offerId: string;
    'X-Authorization'?: string;
    body: SamOfferVariantRequestDtoDto
  },
  context?: HttpContext

): Observable<SamDraftResponseDtoDto> {

    return this.updateOfferWithVariant$Response(params,context).pipe(
      map((r: StrictHttpResponse<SamDraftResponseDtoDto>) => r.body as SamDraftResponseDtoDto)
    );
  }

  /**
   * Path part for operation prepareSummary
   */
  static readonly PrepareSummaryPath = '/draft/{offerId}/prepare-summary';

  /**
   * Collect all data available for the offer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `prepareSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  prepareSummary$Response(params: {

    /**
     * ID of the offer draft to accept
     */
    offerId: string;
    'X-Authorization'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PolicyDraftService.PrepareSummaryPath, 'put');
    if (params) {
      rb.path('offerId', params.offerId, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Collect all data available for the offer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `prepareSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  prepareSummary(params: {

    /**
     * ID of the offer draft to accept
     */
    offerId: string;
    'X-Authorization'?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.prepareSummary$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateOfferWithPaymentDetails
   */
  static readonly UpdateOfferWithPaymentDetailsPath = '/draft/{offerId}/payment-details';

  /**
   * Update policy draft about payment details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOfferWithPaymentDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOfferWithPaymentDetails$Response(params: {
    offerId: string;

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    'X-Authorization'?: string;
    body: SamOfferPaymentDetailsRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SamPaymentDetailsResponseDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, PolicyDraftService.UpdateOfferWithPaymentDetailsPath, 'put');
    if (params) {
      rb.path('offerId', params.offerId, {});
      rb.query('caller', params.caller, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SamPaymentDetailsResponseDtoDto>;
      })
    );
  }

  /**
   * Update policy draft about payment details
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOfferWithPaymentDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOfferWithPaymentDetails(params: {
    offerId: string;

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    'X-Authorization'?: string;
    body: SamOfferPaymentDetailsRequestDtoDto
  },
  context?: HttpContext

): Observable<SamPaymentDetailsResponseDtoDto> {

    return this.updateOfferWithPaymentDetails$Response(params,context).pipe(
      map((r: StrictHttpResponse<SamPaymentDetailsResponseDtoDto>) => r.body as SamPaymentDetailsResponseDtoDto)
    );
  }

  /**
   * Path part for operation confirmOffer
   */
  static readonly ConfirmOfferPath = '/draft/{offerId}/confirm';

  /**
   * Confirm prepared policy.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirmOffer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmOffer$Response(params: {

    /**
     * ID of the offer draft to accept
     */
    offerId: string;
    'X-Authorization'?: string;
    body?: SamDraftConfirmRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SamDraftConfirmResponseDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, PolicyDraftService.ConfirmOfferPath, 'put');
    if (params) {
      rb.path('offerId', params.offerId, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SamDraftConfirmResponseDtoDto>;
      })
    );
  }

  /**
   * Confirm prepared policy.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `confirmOffer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmOffer(params: {

    /**
     * ID of the offer draft to accept
     */
    offerId: string;
    'X-Authorization'?: string;
    body?: SamDraftConfirmRequestDtoDto
  },
  context?: HttpContext

): Observable<SamDraftConfirmResponseDtoDto> {

    return this.confirmOffer$Response(params,context).pipe(
      map((r: StrictHttpResponse<SamDraftConfirmResponseDtoDto>) => r.body as SamDraftConfirmResponseDtoDto)
    );
  }

  /**
   * Path part for operation updateOfferWithCardDetails
   */
  static readonly UpdateOfferWithCardDetailsPath = '/draft/{offerId}/card-details';

  /**
   * Update policy draft about card details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOfferWithCardDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOfferWithCardDetails$Response(params: {
    offerId: string;

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    'X-Authorization'?: string;
    body: SamOfferCardDetailsRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SamCardDetailsResponseDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, PolicyDraftService.UpdateOfferWithCardDetailsPath, 'put');
    if (params) {
      rb.path('offerId', params.offerId, {});
      rb.query('caller', params.caller, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SamCardDetailsResponseDtoDto>;
      })
    );
  }

  /**
   * Update policy draft about card details
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOfferWithCardDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOfferWithCardDetails(params: {
    offerId: string;

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    'X-Authorization'?: string;
    body: SamOfferCardDetailsRequestDtoDto
  },
  context?: HttpContext

): Observable<SamCardDetailsResponseDtoDto> {

    return this.updateOfferWithCardDetails$Response(params,context).pipe(
      map((r: StrictHttpResponse<SamCardDetailsResponseDtoDto>) => r.body as SamCardDetailsResponseDtoDto)
    );
  }

  /**
   * Path part for operation acceptOffer
   */
  static readonly AcceptOfferPath = '/draft/{offerId}/accept';

  /**
   * Accept existing draft of the policy. As a result new policy will be created.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptOffer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  acceptOffer$Response(params: {

    /**
     * Caller
     */
    caller: 'API' | 'FEC';

    /**
     * ID of the offer draft to accept
     */
    offerId: string;
    'X-Authorization'?: string;
    body?: SamAcceptRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AcceptOffer200ResponseDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, PolicyDraftService.AcceptOfferPath, 'put');
    if (params) {
      rb.query('caller', params.caller, {});
      rb.path('offerId', params.offerId, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AcceptOffer200ResponseDtoDto>;
      })
    );
  }

  /**
   * Accept existing draft of the policy. As a result new policy will be created.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `acceptOffer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  acceptOffer(params: {

    /**
     * Caller
     */
    caller: 'API' | 'FEC';

    /**
     * ID of the offer draft to accept
     */
    offerId: string;
    'X-Authorization'?: string;
    body?: SamAcceptRequestDto
  },
  context?: HttpContext

): Observable<AcceptOffer200ResponseDtoDto> {

    return this.acceptOffer$Response(params,context).pipe(
      map((r: StrictHttpResponse<AcceptOffer200ResponseDtoDto>) => r.body as AcceptOffer200ResponseDtoDto)
    );
  }

  /**
   * Path part for operation createDraftPolicy
   */
  static readonly CreateDraftPolicyPath = '/draft';

  /**
   * Create new draft offer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDraftPolicy()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDraftPolicy$Response(params: {
    body: SamDraftRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SamDraftResponseDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, PolicyDraftService.CreateDraftPolicyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SamDraftResponseDtoDto>;
      })
    );
  }

  /**
   * Create new draft offer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createDraftPolicy$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDraftPolicy(params: {
    body: SamDraftRequestDtoDto
  },
  context?: HttpContext

): Observable<SamDraftResponseDtoDto> {

    return this.createDraftPolicy$Response(params,context).pipe(
      map((r: StrictHttpResponse<SamDraftResponseDtoDto>) => r.body as SamDraftResponseDtoDto)
    );
  }

  /**
   * Path part for operation issueNewPolicy
   */
  static readonly IssueNewPolicyPath = '/draft/issue';

  /**
   * Issue new policy
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issueNewPolicy()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  issueNewPolicy$Response(params: {

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    'X-Authorization'?: string;
    body: SamIssueRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SamIssueResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PolicyDraftService.IssueNewPolicyPath, 'post');
    if (params) {
      rb.query('caller', params.caller, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SamIssueResponseDto>;
      })
    );
  }

  /**
   * Issue new policy
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issueNewPolicy$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  issueNewPolicy(params: {

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    'X-Authorization'?: string;
    body: SamIssueRequestDto
  },
  context?: HttpContext

): Observable<SamIssueResponseDto> {

    return this.issueNewPolicy$Response(params,context).pipe(
      map((r: StrictHttpResponse<SamIssueResponseDto>) => r.body as SamIssueResponseDto)
    );
  }

  /**
   * Path part for operation getOfferSummary
   */
  static readonly GetOfferSummaryPath = '/draft/{offerId}';

  /**
   * Offer summary
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOfferSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOfferSummary$Response(params: {
    offerId: string;

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    'X-Authorization'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SamOfferSummaryResponseDtoDto>> {

    const rb = new RequestBuilder(this.rootUrl, PolicyDraftService.GetOfferSummaryPath, 'get');
    if (params) {
      rb.path('offerId', params.offerId, {});
      rb.query('caller', params.caller, {});
      rb.header('X-Authorization', params['X-Authorization'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SamOfferSummaryResponseDtoDto>;
      })
    );
  }

  /**
   * Offer summary
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOfferSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOfferSummary(params: {
    offerId: string;

    /**
     * Caller
     */
    caller: 'API' | 'FEC';
    'X-Authorization'?: string;
  },
  context?: HttpContext

): Observable<SamOfferSummaryResponseDtoDto> {

    return this.getOfferSummary$Response(params,context).pipe(
      map((r: StrictHttpResponse<SamOfferSummaryResponseDtoDto>) => r.body as SamOfferSummaryResponseDtoDto)
    );
  }

}
