/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { PaymentApiConfiguration } from '../payment-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InstallmentsPostRequestDtoDto } from '../models/installments-post-request-dto-dto';
import { PamGenerateInstallmentsResponseDto } from '../models/pam-generate-installments-response-dto';


/**
 * the PamCommandGenerateInstallments API
 */
@Injectable({
  providedIn: 'root',
})
export class PamCommandGenerateInstallmentsService extends BaseService {
  constructor(
    config: PaymentApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation installmentsPost
   */
  static readonly InstallmentsPostPath = '/installments';

  /**
   * Create installments for policy.
   *
   * Create new installments for a specified policy
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `installmentsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  installmentsPost$Response(params?: {
    body?: InstallmentsPostRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PamGenerateInstallmentsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PamCommandGenerateInstallmentsService.InstallmentsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PamGenerateInstallmentsResponseDto>;
      })
    );
  }

  /**
   * Create installments for policy.
   *
   * Create new installments for a specified policy
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `installmentsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  installmentsPost(params?: {
    body?: InstallmentsPostRequestDtoDto
  },
  context?: HttpContext

): Observable<PamGenerateInstallmentsResponseDto> {

    return this.installmentsPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<PamGenerateInstallmentsResponseDto>) => r.body as PamGenerateInstallmentsResponseDto)
    );
  }

}
