/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { OfferApiConfiguration } from '../offer-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PomBenefitDto } from '../models/pom-benefit-dto';
import { PomBenefitListDto } from '../models/pom-benefit-list-dto';
import { PomCreateBenefitRequestDto } from '../models/pom-create-benefit-request-dto';
import { PomCreateBenefitResponseDto } from '../models/pom-create-benefit-response-dto';
import { PomUpdateBenefitRequestDto } from '../models/pom-update-benefit-request-dto';

@Injectable({
  providedIn: 'root',
})
export class BenefitService extends BaseService {
  constructor(
    config: OfferApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation details1
   */
  static readonly Details1Path = '/benefit/{benefitId}';

  /**
   * Get details for one benefit
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `details1()` instead.
   *
   * This method doesn't expect any request body.
   */
  details1$Response(params: {
    benefitId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomBenefitDto>> {

    const rb = new RequestBuilder(this.rootUrl, BenefitService.Details1Path, 'get');
    if (params) {
      rb.path('benefitId', params.benefitId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomBenefitDto>;
      })
    );
  }

  /**
   * Get details for one benefit
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `details1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  details1(params: {
    benefitId: number;
  },
  context?: HttpContext

): Observable<PomBenefitDto> {

    return this.details1$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomBenefitDto>) => r.body as PomBenefitDto)
    );
  }

  /**
   * Path part for operation update8
   */
  static readonly Update8Path = '/benefit/{benefitId}';

  /**
   * Update benefit
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update8()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update8$Response(params: {
    benefitId: number;
    body: PomUpdateBenefitRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BenefitService.Update8Path, 'put');
    if (params) {
      rb.path('benefitId', params.benefitId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update benefit
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update8$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update8(params: {
    benefitId: number;
    body: PomUpdateBenefitRequestDto
  },
  context?: HttpContext

): Observable<void> {

    return this.update8$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation delete2
   */
  static readonly Delete2Path = '/benefit/{benefitId}';

  /**
   * Removes benefit with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete2()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete2$Response(params: {
    benefitId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomBenefitDto>> {

    const rb = new RequestBuilder(this.rootUrl, BenefitService.Delete2Path, 'delete');
    if (params) {
      rb.path('benefitId', params.benefitId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomBenefitDto>;
      })
    );
  }

  /**
   * Removes benefit with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete2(params: {
    benefitId: number;
  },
  context?: HttpContext

): Observable<PomBenefitDto> {

    return this.delete2$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomBenefitDto>) => r.body as PomBenefitDto)
    );
  }

  /**
   * Path part for operation create10
   */
  static readonly Create10Path = '/benefit';

  /**
   * Create new benefit.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create10()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create10$Response(params: {
    body: PomCreateBenefitRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomCreateBenefitResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, BenefitService.Create10Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomCreateBenefitResponseDto>;
      })
    );
  }

  /**
   * Create new benefit.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create10$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create10(params: {
    body: PomCreateBenefitRequestDto
  },
  context?: HttpContext

): Observable<PomCreateBenefitResponseDto> {

    return this.create10$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomCreateBenefitResponseDto>) => r.body as PomCreateBenefitResponseDto)
    );
  }

  /**
   * Path part for operation list3
   */
  static readonly List3Path = '/product/{productId}/benefit';

  /**
   * Fetch list of benefits with optional filters.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `list3()` instead.
   *
   * This method doesn't expect any request body.
   */
  list3$Response(params: {
    page: number;
    size: number;
    filter?: Array<string>;
    sort?: Array<string>;
    productId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomBenefitListDto>> {

    const rb = new RequestBuilder(this.rootUrl, BenefitService.List3Path, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('filter', params.filter, {});
      rb.query('sort', params.sort, {});
      rb.path('productId', params.productId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomBenefitListDto>;
      })
    );
  }

  /**
   * Fetch list of benefits with optional filters.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `list3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  list3(params: {
    page: number;
    size: number;
    filter?: Array<string>;
    sort?: Array<string>;
    productId: number;
  },
  context?: HttpContext

): Observable<PomBenefitListDto> {

    return this.list3$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomBenefitListDto>) => r.body as PomBenefitListDto)
    );
  }

}
