/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { PersonApiConfiguration } from '../person-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PemConsentRequestDtoDto } from '../models/pem-consent-request-dto-dto';
import { PemConsentsResponseDto } from '../models/pem-consents-response-dto';
import { PemSingleConsentDto } from '../models/pem-single-consent-dto';


/**
 * the Consent API
 */
@Injectable({
  providedIn: 'root',
})
export class ConsentService extends BaseService {
  constructor(
    config: PersonApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation policyConsentList
   */
  static readonly PolicyConsentListPath = '/consent';

  /**
   * Get list of consents for policy
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `policyConsentList()` instead.
   *
   * This method doesn't expect any request body.
   */
  policyConsentList$Response(params: {

    /**
     * UID of the policy for whom consents will be collected
     */
    policyUID: string;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PemConsentsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ConsentService.PolicyConsentListPath, 'get');
    if (params) {
      rb.query('policyUID', params.policyUID, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PemConsentsResponseDto>;
      })
    );
  }

  /**
   * Get list of consents for policy
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `policyConsentList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  policyConsentList(params: {

    /**
     * UID of the policy for whom consents will be collected
     */
    policyUID: string;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<PemConsentsResponseDto> {

    return this.policyConsentList$Response(params,context).pipe(
      map((r: StrictHttpResponse<PemConsentsResponseDto>) => r.body as PemConsentsResponseDto)
    );
  }

  /**
   * Path part for operation consentUpdateWithoutId
   */
  static readonly ConsentUpdateWithoutIdPath = '/consent';

  /**
   * Update consent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consentUpdateWithoutId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  consentUpdateWithoutId$Response(params: {
    body: PemConsentRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PemSingleConsentDto>> {

    const rb = new RequestBuilder(this.rootUrl, ConsentService.ConsentUpdateWithoutIdPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PemSingleConsentDto>;
      })
    );
  }

  /**
   * Update consent
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consentUpdateWithoutId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  consentUpdateWithoutId(params: {
    body: PemConsentRequestDtoDto
  },
  context?: HttpContext

): Observable<PemSingleConsentDto> {

    return this.consentUpdateWithoutId$Response(params,context).pipe(
      map((r: StrictHttpResponse<PemSingleConsentDto>) => r.body as PemSingleConsentDto)
    );
  }

  /**
   * Path part for operation consentCreate
   */
  static readonly ConsentCreatePath = '/consent';

  /**
   * Create consent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consentCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  consentCreate$Response(params: {
    body: PemConsentRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PemSingleConsentDto>> {

    const rb = new RequestBuilder(this.rootUrl, ConsentService.ConsentCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PemSingleConsentDto>;
      })
    );
  }

  /**
   * Create consent
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consentCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  consentCreate(params: {
    body: PemConsentRequestDtoDto
  },
  context?: HttpContext

): Observable<PemSingleConsentDto> {

    return this.consentCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<PemSingleConsentDto>) => r.body as PemSingleConsentDto)
    );
  }

  /**
   * Path part for operation consentFetch
   */
  static readonly ConsentFetchPath = '/consent/{consentId}';

  /**
   * Get single consent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consentFetch()` instead.
   *
   * This method doesn't expect any request body.
   */
  consentFetch$Response(params: {

    /**
     * Consent id
     */
    consentId: number;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PemSingleConsentDto>> {

    const rb = new RequestBuilder(this.rootUrl, ConsentService.ConsentFetchPath, 'get');
    if (params) {
      rb.path('consentId', params.consentId, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PemSingleConsentDto>;
      })
    );
  }

  /**
   * Get single consent
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consentFetch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consentFetch(params: {

    /**
     * Consent id
     */
    consentId: number;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<PemSingleConsentDto> {

    return this.consentFetch$Response(params,context).pipe(
      map((r: StrictHttpResponse<PemSingleConsentDto>) => r.body as PemSingleConsentDto)
    );
  }

  /**
   * Path part for operation consentUpdate
   */
  static readonly ConsentUpdatePath = '/consent/{consentId}';

  /**
   * Update consent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consentUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  consentUpdate$Response(params: {

    /**
     * Consent id
     */
    consentId: number;
    'Accept-Language'?: string;
    body: PemConsentRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PemSingleConsentDto>> {

    const rb = new RequestBuilder(this.rootUrl, ConsentService.ConsentUpdatePath, 'put');
    if (params) {
      rb.path('consentId', params.consentId, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PemSingleConsentDto>;
      })
    );
  }

  /**
   * Update consent
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consentUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  consentUpdate(params: {

    /**
     * Consent id
     */
    consentId: number;
    'Accept-Language'?: string;
    body: PemConsentRequestDtoDto
  },
  context?: HttpContext

): Observable<PemSingleConsentDto> {

    return this.consentUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<PemSingleConsentDto>) => r.body as PemSingleConsentDto)
    );
  }

  /**
   * Path part for operation consentList
   */
  static readonly ConsentListPath = '/person/{personUID}/consent';

  /**
   * Get list of consents for person
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consentList()` instead.
   *
   * This method doesn't expect any request body.
   */
  consentList$Response(params: {

    /**
     * UID of the person for whom consents will be collected
     */
    personUID: string;

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * List will be sorted by this values
     */
    sort?: Array<string>;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PemConsentsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ConsentService.ConsentListPath, 'get');
    if (params) {
      rb.path('personUID', params.personUID, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('sort', params.sort, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PemConsentsResponseDto>;
      })
    );
  }

  /**
   * Get list of consents for person
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consentList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consentList(params: {

    /**
     * UID of the person for whom consents will be collected
     */
    personUID: string;

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * List will be sorted by this values
     */
    sort?: Array<string>;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<PemConsentsResponseDto> {

    return this.consentList$Response(params,context).pipe(
      map((r: StrictHttpResponse<PemConsentsResponseDto>) => r.body as PemConsentsResponseDto)
    );
  }

  /**
   * Path part for operation personPersonUidConsentDelete
   */
  static readonly PersonPersonUidConsentDeletePath = '/person/{personUID}/consent';

  /**
   * Delete all consents for the person.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personPersonUidConsentDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  personPersonUidConsentDelete$Response(params: {

    /**
     * UID of the person for whom consents will be collected
     */
    personUID: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ConsentService.PersonPersonUidConsentDeletePath, 'delete');
    if (params) {
      rb.path('personUID', params.personUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete all consents for the person.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `personPersonUidConsentDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personPersonUidConsentDelete(params: {

    /**
     * UID of the person for whom consents will be collected
     */
    personUID: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.personPersonUidConsentDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
