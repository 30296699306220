/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { CommunicationApiConfiguration } from '../communication-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CmmDefinitionRequestDtoDto } from '../models/cmm-definition-request-dto-dto';
import { DefinitionResponseDto } from '../models/definition-response-dto';
import { DefinitionsResponseDto } from '../models/definitions-response-dto';
import { ExportProductNotificationDefinitionsResponseDto } from '../models/export-product-notification-definitions-response-dto';
import { ImportProductNotificationDefinitionsRequestDto } from '../models/import-product-notification-definitions-request-dto';
import { ImportProductNotificationDefinitionsResponseDto } from '../models/import-product-notification-definitions-response-dto';


/**
 * the Definition API
 */
@Injectable({
  providedIn: 'root',
})
export class DefinitionService extends BaseService {
  constructor(
    config: CommunicationApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation definitionFetch
   */
  static readonly DefinitionFetchPath = '/notification-definition/{id}';

  /**
   * Get details of definition
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `definitionFetch()` instead.
   *
   * This method doesn't expect any request body.
   */
  definitionFetch$Response(params: {
    id: number;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DefinitionResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DefinitionService.DefinitionFetchPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DefinitionResponseDto>;
      })
    );
  }

  /**
   * Get details of definition
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `definitionFetch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  definitionFetch(params: {
    id: number;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<DefinitionResponseDto> {

    return this.definitionFetch$Response(params,context).pipe(
      map((r: StrictHttpResponse<DefinitionResponseDto>) => r.body as DefinitionResponseDto)
    );
  }

  /**
   * Path part for operation definitionUpdate
   */
  static readonly DefinitionUpdatePath = '/notification-definition/{id}';

  /**
   * Update definition
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `definitionUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  definitionUpdate$Response(params: {
    id: number;
    body: CmmDefinitionRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DefinitionResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DefinitionService.DefinitionUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DefinitionResponseDto>;
      })
    );
  }

  /**
   * Update definition
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `definitionUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  definitionUpdate(params: {
    id: number;
    body: CmmDefinitionRequestDtoDto
  },
  context?: HttpContext

): Observable<DefinitionResponseDto> {

    return this.definitionUpdate$Response(params,context).pipe(
      map((r: StrictHttpResponse<DefinitionResponseDto>) => r.body as DefinitionResponseDto)
    );
  }

  /**
   * Path part for operation productNotificationDefinitionListExport
   */
  static readonly ProductNotificationDefinitionListExportPath = '/product-notification-definition';

  /**
   * Get list of the definitions per product
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productNotificationDefinitionListExport()` instead.
   *
   * This method doesn't expect any request body.
   */
  productNotificationDefinitionListExport$Response(params: {
    product: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ExportProductNotificationDefinitionsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DefinitionService.ProductNotificationDefinitionListExportPath, 'get');
    if (params) {
      rb.query('product', params.product, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExportProductNotificationDefinitionsResponseDto>;
      })
    );
  }

  /**
   * Get list of the definitions per product
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `productNotificationDefinitionListExport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productNotificationDefinitionListExport(params: {
    product: string;
  },
  context?: HttpContext

): Observable<ExportProductNotificationDefinitionsResponseDto> {

    return this.productNotificationDefinitionListExport$Response(params,context).pipe(
      map((r: StrictHttpResponse<ExportProductNotificationDefinitionsResponseDto>) => r.body as ExportProductNotificationDefinitionsResponseDto)
    );
  }

  /**
   * Path part for operation productNotificationDefinitionListImport
   */
  static readonly ProductNotificationDefinitionListImportPath = '/product-notification-definition';

  /**
   * Import product definitions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productNotificationDefinitionListImport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  productNotificationDefinitionListImport$Response(params: {
    body: ImportProductNotificationDefinitionsRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ImportProductNotificationDefinitionsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DefinitionService.ProductNotificationDefinitionListImportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImportProductNotificationDefinitionsResponseDto>;
      })
    );
  }

  /**
   * Import product definitions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `productNotificationDefinitionListImport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  productNotificationDefinitionListImport(params: {
    body: ImportProductNotificationDefinitionsRequestDto
  },
  context?: HttpContext

): Observable<ImportProductNotificationDefinitionsResponseDto> {

    return this.productNotificationDefinitionListImport$Response(params,context).pipe(
      map((r: StrictHttpResponse<ImportProductNotificationDefinitionsResponseDto>) => r.body as ImportProductNotificationDefinitionsResponseDto)
    );
  }

  /**
   * Path part for operation definitionList
   */
  static readonly DefinitionListPath = '/notification-definition';

  /**
   * Get list of the definitions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `definitionList()` instead.
   *
   * This method doesn't expect any request body.
   */
  definitionList$Response(params: {
    page: number;
    size: number;
    filter?: Array<string>;
    sort?: Array<string>;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DefinitionsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DefinitionService.DefinitionListPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('filter', params.filter, {});
      rb.query('sort', params.sort, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DefinitionsResponseDto>;
      })
    );
  }

  /**
   * Get list of the definitions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `definitionList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  definitionList(params: {
    page: number;
    size: number;
    filter?: Array<string>;
    sort?: Array<string>;
    'Accept-Language'?: string;
  },
  context?: HttpContext

): Observable<DefinitionsResponseDto> {

    return this.definitionList$Response(params,context).pipe(
      map((r: StrictHttpResponse<DefinitionsResponseDto>) => r.body as DefinitionsResponseDto)
    );
  }

  /**
   * Path part for operation definitionCreate
   */
  static readonly DefinitionCreatePath = '/notification-definition';

  /**
   * Create new notification definition
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `definitionCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  definitionCreate$Response(params: {
    body: CmmDefinitionRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DefinitionResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DefinitionService.DefinitionCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DefinitionResponseDto>;
      })
    );
  }

  /**
   * Create new notification definition
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `definitionCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  definitionCreate(params: {
    body: CmmDefinitionRequestDtoDto
  },
  context?: HttpContext

): Observable<DefinitionResponseDto> {

    return this.definitionCreate$Response(params,context).pipe(
      map((r: StrictHttpResponse<DefinitionResponseDto>) => r.body as DefinitionResponseDto)
    );
  }

}
