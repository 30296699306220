/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { OfferApiConfiguration } from '../offer-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PomActivateFormRequestDto } from '../models/pom-activate-form-request-dto';
import { PomCreateFormRequestDtoDto } from '../models/pom-create-form-request-dto-dto';
import { PomCreateFormResponseDto } from '../models/pom-create-form-response-dto';
import { PomFormDto } from '../models/pom-form-dto';
import { PomFormListDto } from '../models/pom-form-list-dto';
import { PomFormReferenceRequestDto } from '../models/pom-form-reference-request-dto';
import { PomUpdateFormRequestDtoDto } from '../models/pom-update-form-request-dto-dto';
import { ResultDetailsDto } from '../models/result-details-dto';

@Injectable({
  providedIn: 'root',
})
export class FormServicesService extends BaseService {
  constructor(
    config: OfferApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation fetch8
   */
  static readonly Fetch8Path = '/form/{formId}';

  /**
   * Get details for one form
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetch8()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetch8$Response(params: {

    /**
     * Id of the form that should be get
     */
    formId: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomFormDto>> {

    const rb = new RequestBuilder(this.rootUrl, FormServicesService.Fetch8Path, 'get');
    if (params) {
      rb.path('formId', params.formId, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomFormDto>;
      })
    );
  }

  /**
   * Get details for one form
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fetch8$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetch8(params: {

    /**
     * Id of the form that should be get
     */
    formId: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<PomFormDto> {

    return this.fetch8$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomFormDto>) => r.body as PomFormDto)
    );
  }

  /**
   * Path part for operation update6
   */
  static readonly Update6Path = '/form/{formId}';

  /**
   * Update form with new model
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update6()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update6$Response(params: {

    /**
     * Id of the form that should be updated
     */
    formId: number;
    'Accept-Language'?: any;
    body: PomUpdateFormRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ResultDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, FormServicesService.Update6Path, 'put');
    if (params) {
      rb.path('formId', params.formId, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultDetailsDto>;
      })
    );
  }

  /**
   * Update form with new model
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update6$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update6(params: {

    /**
     * Id of the form that should be updated
     */
    formId: number;
    'Accept-Language'?: any;
    body: PomUpdateFormRequestDtoDto
  },
  context?: HttpContext

): Observable<ResultDetailsDto> {

    return this.update6$Response(params,context).pipe(
      map((r: StrictHttpResponse<ResultDetailsDto>) => r.body as ResultDetailsDto)
    );
  }

  /**
   * Path part for operation list10
   */
  static readonly List10Path = '/form';

  /**
   * Fetch list of forms with optional filters.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `list10()` instead.
   *
   * This method doesn't expect any request body.
   */
  list10$Response(params: {

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * Filter that can be applied to the list of results
     */
    filter?: Array<string>;

    /**
     * Sorting that can be applied to the list of results
     */
    sort?: Array<string>;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomFormListDto>> {

    const rb = new RequestBuilder(this.rootUrl, FormServicesService.List10Path, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('filter', params.filter, {});
      rb.query('sort', params.sort, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomFormListDto>;
      })
    );
  }

  /**
   * Fetch list of forms with optional filters.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `list10$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  list10(params: {

    /**
     * Number of page
     */
    page: number;

    /**
     * Size of page
     */
    size: number;

    /**
     * Filter that can be applied to the list of results
     */
    filter?: Array<string>;

    /**
     * Sorting that can be applied to the list of results
     */
    sort?: Array<string>;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<PomFormListDto> {

    return this.list10$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomFormListDto>) => r.body as PomFormListDto)
    );
  }

  /**
   * Path part for operation create8
   */
  static readonly Create8Path = '/form';

  /**
   * Create new form.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create8()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create8$Response(params: {
    'Accept-Language'?: any;
    body: PomCreateFormRequestDtoDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomCreateFormResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, FormServicesService.Create8Path, 'post');
    if (params) {
      rb.header('Accept-Language', params['Accept-Language'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomCreateFormResponseDto>;
      })
    );
  }

  /**
   * Create new form.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create8$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create8(params: {
    'Accept-Language'?: any;
    body: PomCreateFormRequestDtoDto
  },
  context?: HttpContext

): Observable<PomCreateFormResponseDto> {

    return this.create8$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomCreateFormResponseDto>) => r.body as PomCreateFormResponseDto)
    );
  }

  /**
   * Path part for operation updateReferences
   */
  static readonly UpdateReferencesPath = '/form/{formId}/reference';

  /**
   * Update references for form in active state
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateReferences()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReferences$Response(params: {
    formId: number;
    body: PomFormReferenceRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ResultDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, FormServicesService.UpdateReferencesPath, 'post');
    if (params) {
      rb.path('formId', params.formId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultDetailsDto>;
      })
    );
  }

  /**
   * Update references for form in active state
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateReferences$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReferences(params: {
    formId: number;
    body: PomFormReferenceRequestDto
  },
  context?: HttpContext

): Observable<ResultDetailsDto> {

    return this.updateReferences$Response(params,context).pipe(
      map((r: StrictHttpResponse<ResultDetailsDto>) => r.body as ResultDetailsDto)
    );
  }

  /**
   * Path part for operation deactivate
   */
  static readonly DeactivatePath = '/form/{formId}/deactivate';

  /**
   * Deactivate form with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deactivate$Response(params: {
    formId: number;
    body: PomActivateFormRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ResultDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, FormServicesService.DeactivatePath, 'post');
    if (params) {
      rb.path('formId', params.formId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultDetailsDto>;
      })
    );
  }

  /**
   * Deactivate form with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deactivate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deactivate(params: {
    formId: number;
    body: PomActivateFormRequestDto
  },
  context?: HttpContext

): Observable<ResultDetailsDto> {

    return this.deactivate$Response(params,context).pipe(
      map((r: StrictHttpResponse<ResultDetailsDto>) => r.body as ResultDetailsDto)
    );
  }

  /**
   * Path part for operation copy
   */
  static readonly CopyPath = '/form/{formId}/copy';

  /**
   * Copy form with all dependencies
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copy()` instead.
   *
   * This method doesn't expect any request body.
   */
  copy$Response(params: {

    /**
     * Id of the form that should be copied
     */
    formId: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PomFormDto>> {

    const rb = new RequestBuilder(this.rootUrl, FormServicesService.CopyPath, 'post');
    if (params) {
      rb.path('formId', params.formId, {});
      rb.header('Accept-Language', params['Accept-Language'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PomFormDto>;
      })
    );
  }

  /**
   * Copy form with all dependencies
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `copy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  copy(params: {

    /**
     * Id of the form that should be copied
     */
    formId: number;
    'Accept-Language'?: any;
  },
  context?: HttpContext

): Observable<PomFormDto> {

    return this.copy$Response(params,context).pipe(
      map((r: StrictHttpResponse<PomFormDto>) => r.body as PomFormDto)
    );
  }

  /**
   * Path part for operation activate
   */
  static readonly ActivatePath = '/form/{formId}/activate';

  /**
   * Activate form with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activate$Response(params: {
    formId: number;
    body: PomActivateFormRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ResultDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, FormServicesService.ActivatePath, 'post');
    if (params) {
      rb.path('formId', params.formId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultDetailsDto>;
      })
    );
  }

  /**
   * Activate form with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activate(params: {
    formId: number;
    body: PomActivateFormRequestDto
  },
  context?: HttpContext

): Observable<ResultDetailsDto> {

    return this.activate$Response(params,context).pipe(
      map((r: StrictHttpResponse<ResultDetailsDto>) => r.body as ResultDetailsDto)
    );
  }

}
