/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaymentApiConfiguration, PaymentApiConfigurationParams } from './payment-api-configuration';

import { PamQueryInstallmentService } from './services/pam-query-installment.service';
import { PamCommandUpdateInstallmentService } from './services/pam-command-update-installment.service';
import { PmaPaymentNotificationCommandService } from './services/pma-payment-notification-command.service';
import { PaymentNotificationsService } from './services/payment-notifications.service';
import { PmaCommandRecieveMerchantNotificationService } from './services/pma-command-recieve-merchant-notification.service';
import { PamQueryListInstallmentsService } from './services/pam-query-list-installments.service';
import { PamCommandGenerateInstallmentsService } from './services/pam-command-generate-installments.service';
import { PamCommandSendInstallmentLinkService } from './services/pam-command-send-installment-link.service';
import { PamCommandInstallmentExecutePaymentLinkService } from './services/pam-command-installment-execute-payment-link.service';
import { PmaQueryReadPaymentService } from './services/pma-query-read-payment.service';
import { PmaGetPosIdNumberService } from './services/pma-get-pos-id-number.service';
import { PamQueryInstallmentProductSummaryService } from './services/pam-query-installment-product-summary.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    PamQueryInstallmentService,
    PamCommandUpdateInstallmentService,
    PmaPaymentNotificationCommandService,
    PaymentNotificationsService,
    PmaCommandRecieveMerchantNotificationService,
    PamQueryListInstallmentsService,
    PamCommandGenerateInstallmentsService,
    PamCommandSendInstallmentLinkService,
    PamCommandInstallmentExecutePaymentLinkService,
    PmaQueryReadPaymentService,
    PmaGetPosIdNumberService,
    PamQueryInstallmentProductSummaryService,
    PaymentApiConfiguration
  ],
})
export class PaymentApiModule {
  static forRoot(params: PaymentApiConfigurationParams): ModuleWithProviders<PaymentApiModule> {
    return {
      ngModule: PaymentApiModule,
      providers: [
        {
          provide: PaymentApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: PaymentApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('PaymentApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
